import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isAuthenticated: true,
  },
  reducers: {
    login: (state, token) => {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem('token', token.payload);
      }
      state.isAuthenticated = true;
    },
    logout: state => {
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem('token');
      }
      state.isAuthenticated = false;
    },
  },
});

export const { login, logout } = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.login.value)`
export const isAuthenticated = state => state.login.isAuthenticated;

export default loginSlice.reducer;
