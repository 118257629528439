import React from 'react';
import { Nav, Pagination } from 'react-bootstrap';

const Paging = ({ page, totalPages, changePage }) => {
  const last = totalPages;

  return (
    <Nav className="justify-content-center">
      <Pagination>
        {page > 2 && <Pagination.First onClick={() => changePage(1)} />}
        {page - 2 >= 1 && (
          <Pagination.Item onClick={() => changePage(page - 2)}>
            {page - 2}
          </Pagination.Item>
        )}
        {page - 1 >= 1 && (
          <Pagination.Item onClick={() => changePage(page - 1)}>
            {page - 1}
          </Pagination.Item>
        )}
        <Pagination.Item active onClick={() => changePage(page)}>
          {page}
        </Pagination.Item>
        {page + 1 <= last && (
          <Pagination.Item onClick={() => changePage(page + 1)}>
            {page + 1}
          </Pagination.Item>
        )}
        {page + 2 <= last && (
          <Pagination.Item onClick={() => changePage(page + 2)}>
            {page + 2}
          </Pagination.Item>
        )}
        {page < last - 1 && (
          <Pagination.Last onClick={() => changePage(last)} />
        )}
      </Pagination>
    </Nav>
  );
};

export default Paging;
