import { createSlice } from '@reduxjs/toolkit';

export const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    notifications: [],
  },
  reducers: {
    addNotification(state, payload) {
      state.notifications = [...state.notifications, payload.payload];
    },
    removeNotification(state) {
      const notifications = [...state.notifications];
      notifications.shift();
      state.notifications = [...notifications];
    },
  },
});

export const { addNotification, removeNotification } = toastSlice.actions;

export const displayNotification = payload => dispatch => {
  dispatch(addNotification(payload));
  setTimeout(() => {
    dispatch(removeNotification());
  }, 4000);
};

export const notificationsArray = state => state.toast.notifications;

export default toastSlice.reducer;
