import { createSlice } from '@reduxjs/toolkit';

export const researchesSlice = createSlice({
  name: 'researches',
  initialState: {
    researches: [],
  },
  reducers: {
    setResearches: (state, action) => {
      state.researches = action.payload;
    },
    clearResearches: state => {
      state.researches = [];
    },
  },
});

export const { setResearches, clearResearches } = researchesSlice.actions;

export const selectResearches = state => state.researches.researches;

// export const selectProductResearches = (state, action) => {
//   return state.researches.researches.filter((research) => {
//     return research.type === action.payload
//   });
// };

export default researchesSlice.reducer;
