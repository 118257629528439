import { createSlice } from '@reduxjs/toolkit';

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    clients: [],
    clientInterface: [
      {
        target: 'company',
        question: 'Em qual empresa você trabalha?',
        responseLabel: 'Empresa:',
        responsePlaceholder: 'Nome da empresa',
        type: 1,
      },
      {
        target: 'email',
        question: 'Qual é o seu email corporativo?',
        responseLabel: 'Email:',
        responsePlaceholder: 'Email corporativo',
        type: 3,
      },
      {
        target: 'motive',
        question: 'Qual o motivo da sua procura?',
        responseLabel: 'Motivo:',
        responsePlaceholder: 'Escolha o motivo',
        type: 12,
        allowOther: true,
        otherOption: 'Outro',
        otherLabel: 'Outro:',
        otherPlaceholder: 'Qual outro motivo?',
        responseOptions: [
          {
            weight: 1,
            name: 'Não tenho conhecimento algum de LGPD e preciso de ajuda',
          },
          {
            weight: 2,
            name:
              'Conheço um pouco sobre LGPD e quero me antecipar / adequar da forma correta',
          },
          {
            weight: 3,
            name:
              'Tive um vazamento ou fui atacado(a) recentemente e preciso me adequar urgente',
          },
          {
            weight: 4,
            name:
              'Sou DPO da minha empresa e estou buscando uma solução / suporte',
          },
        ],
      },
      {
        target: 'name',
        question: 'Qual o seu nome?',
        responseLabel: 'Nome:',
        responsePlaceholder: 'Nome e sobrenome',
        type: 1,
      },
      {
        target: 'phone',
        question: 'Qual o seu celular?',
        responseLabel: 'Celular:',
        responsePlaceholder: 'Celular',
        type: 1,
        mask: '2',
      },
      {
        target: 'sizeEnterprise',
        question: 'Qual o tamanho da sua empresa?',
        responseLabel: 'Tamanho da empresa:',
        responsePlaceholder: 'Escolha o tamanho da empresa',
        type: 12,
        responseOptions: [
          { weight: 1, name: 'Até 19 pessoas' },
          { weight: 2, name: 'De 20 a 99 pessoas' },
          { weight: 3, name: 'De 100 a 499 pessoas' },
          { weight: 4, name: 'De 500 a 1000 pessoas' },
          { weight: 5, name: 'Acima de 1000 pessoas' },
        ],
      },
    ],
  },
  reducers: {
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    clearClients: state => {
      state.clients = [];
    },
  },
});

export const { setClients, clearClients } = clientsSlice.actions;

export const selectClients = state => state.clients.clients;

export const selectClientInterface = state => state.clients.clientInterface;

export default clientsSlice.reducer;
