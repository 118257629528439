import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Answer from './Answer';
import EmptyResults from '../../components/empty/EmptyResults';

const AnswerParent = () => {
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route
          path="/responder/:productId/:questId/:researchId"
          component={Answer}
        />
        <Route path="/responder/:productId/:questId" component={Answer} />
        <Route path={match.path}>
          <EmptyResults
            message="Página não acessível."
            redirectLink="/"
            redirectAction="Sair"
          />
        </Route>
      </Switch>
    </div>
  );
};

export default AnswerParent;
