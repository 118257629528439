import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: 0,
  },
  reducers: {
    increaseLoadingState: state => {
      state.isLoading += 1;
    },
    decreaseLoadingState: state => {
      state.isLoading = state.isLoading === 0 ? 0 : state.isLoading - 1;
    },
  },
});

export const {
  increaseLoadingState,
  decreaseLoadingState,
} = loadingSlice.actions;

export const isLoading = state => state.loading.isLoading;

export default loadingSlice.reducer;
