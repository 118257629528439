import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Switch,
  Route,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';

import { selectProducts, setProducts } from './productsSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import errorHandler from '../../app/error';
import { get } from '../../app/axios';
import Product from './Product';
import Paging from '../../components/paging/Paging';
import Researches from '../researches/Researches';
import Research from '../researches/Research';

const Products = props => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const products = useSelector(selectProducts);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit] = useState(20);

  const { location } = props;
  const { pathname } = location;
  const previousRoute = pathname.replace(`${pathname}`, '/');
  const history = useHistory();

  const getProducts = useCallback(async () => {
    if (products.length === 0) {
      dispatch(increaseLoadingState());

      try {
        const response = await get(`/products?limit=${limit}&page=${page}`);
        if (response.message) {
          response.context = 'recuperar produtos';
          errorHandler(response);
        }
        if (!response.message && response.products.length > 0) {
          dispatch(setProducts(response.products));
          setTotalPage(response.totalPages);
        }
      } catch (error) {
        error.context = 'recuperar produtos';
        errorHandler(error);
      }
      dispatch(decreaseLoadingState());
    }
  }, [products.length, dispatch, limit, page]);

  useEffect(() => {
    getProducts().then();
  }, [getProducts]);

  const handleChangePage = _page => {
    setPage(_page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <Switch>
        <Route path="/produtos/:productId" component={Product} />
        <Route path="/produtos/:productId/pesquisas" component={Researches} />
        <Route
          path="/produtos/:productId/pesquisas/:researchId"
          component={Research}
        />
        <Route path={match.path}>
          <div className="m-5">
            <div className="mt-5 d-flex justify-content-between">
              <h2 className="mb-3">Produtos</h2>
            </div>
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {products &&
                  products.map((product, i) => (
                    <tr
                      key={`product-${i}`}
                      onClick={() =>
                        history.push(`/produtos/${product.type}/pesquisas`)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <td>{product.name}</td>
                      <td>
                        <small>{product.description}</small>
                      </td>
                      <td>
                        <Link to={`/produtos/${product.type}/pesquisas`}>
                          <Button variant="primary" size="sm">
                            Selecionar
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {totalPages > 1 && (
              <Paging
                page={page}
                totalPages={totalPages}
                changePage={handleChangePage}
              />
            )}
            <Link to={`${previousRoute}`}>
              <Button variant="secondary">Voltar</Button>
            </Link>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default Products;
