import React from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';

import Research from './Research';
import Paging from '../../components/paging/Paging';
import {
  selectDateLocale,
  selectDateOptions,
} from '../../components/date/dateSlice';

const Researches = ({
  researcheResults,
  page,
  totalPages,
  changePage,
  previousRoute,
}) => {
  const match = useRouteMatch();
  const locale = useSelector(selectDateLocale);
  const options = useSelector(selectDateOptions);
  const history = useHistory();

  const getScoreClassName = score => {
    if (score < 0 || score === null || score === undefined) {
      return '';
    }
    if (score < 51) {
      return 'text-danger';
    }
    if (score < 76) {
      return 'text-warning';
    }
    return 'text-success';
  };

  const getScoreText = score => {
    if (score === null || score === undefined) {
      return 'N/A';
    }
    return `${score}%`;
  };

  return (
    <div>
      <Switch>
        <Route
          path={`${previousRoute}/pesquisas/:researchId`}
          component={Research}
        />
        <Route path={match.path}>
          <div className="m-5">
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Celular</th>
                  <th>Email</th>
                  <th>Data</th>
                  <th>Seções</th>
                  <th>Concluída</th>
                  <th>Pontuação</th>
                  <th>Avaliação</th>
                  <th>Situação</th>
                </tr>
              </thead>
              <tbody>
                {researcheResults &&
                  researcheResults.map(
                    (research, i) =>
                      research.clientId && (
                        <tr
                          key={`research-${i}`}
                          onClick={() =>
                            history.push(
                              `${previousRoute}/pesquisas/${research._id}`,
                            )
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <td>{research.clientId.name}</td>
                          <td>{research.clientId.phone}</td>
                          <td>
                            <a href={`mailto:${research.clientId.email}`}>
                              {research.clientId.email}
                            </a>
                          </td>
                          <td>
                            {new Date(research.created_at).toLocaleDateString(
                              locale,
                              options,
                            )}
                          </td>
                          <td>{research.sections.length}</td>
                          <td>{research.status ? 'Sim' : 'Não'}</td>
                          <td className={getScoreClassName(research.score)}>
                            {getScoreText(research.score)}
                          </td>
                          <td>
                            {research.statusEvaluation.charAt(0).toUpperCase() +
                              research.statusEvaluation.slice(1)}
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: `${previousRoute}/pesquisas/${research._id}`,
                                routeProps: { previousRoute },
                              }}
                            >
                              <Button variant="primary" size="sm">
                                Visualizar
                              </Button>
                            </Link>
                          </td>
                        </tr>
                      ),
                  )}
              </tbody>
            </Table>
            {totalPages > 1 && (
              <Paging
                page={page}
                totalPages={totalPages}
                changePage={changePage}
              />
            )}
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default Researches;
