import React from 'react';
import { useSelector } from 'react-redux';

import ToastNotification from './ToastNotification';
import { notificationsArray } from './notificationSlice';

import styles from '../../sass/components/ToastNotifications.module.scss';

const ToastNotifications = () => {
  const notifications = useSelector(notificationsArray);
  return (
    <div className={styles.toastOverflow}>
      {notifications.map((value, index) => {
        return (
          <ToastNotification
            title={value.title}
            text={value.text}
            variant={value.variant}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default ToastNotifications;
