import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { selectProducts } from '../../pages/products/productsSlice';

const Breadcrumbs = props => {
  const { history, location } = props;
  const { pathname } = location;
  const products = useSelector(selectProducts);
  const pathnames = pathname.split('/').filter(x => x);
  const breadcrumbItems = [];
  const routes = [''];

  if (pathnames.includes('produtos')) {
    pathnames.forEach((name, index) => {
      if (index === 0) {
        routes[0] = `/${pathnames.slice(0, index + 1).join('/')}`;
        breadcrumbItems.push('Produtos');
      } else if (index <= 2) {
        routes[1] = `/${pathnames.slice(0, index + 1).join('/')}`;

        products.forEach(prod => {
          if (name === prod.type) {
            breadcrumbItems.push(`${prod.name} - Pesquisas`);
          }
        });
      } else if (name.length > 20) {
        breadcrumbItems.push(`Pesquisa ${name}`);
        routes[2] = `/${pathnames.slice(0, index + 1).join('/')}`;
      }
    });
  } else if (pathnames.includes('clientes')) {
    pathnames.forEach((name, index) => {
      switch (index) {
        case 0:
          breadcrumbItems.push('Clientes');
          routes[0] = `/${pathnames.slice(0, index + 1).join('/')}`;
          break;
        case 1:
          breadcrumbItems.push(`Cliente ${name}`);
          routes[1] = `/${pathnames.slice(0, index + 1).join('/')}`;
          break;
        // case 3:;
        default:
          breadcrumbItems.push(`Pesquisa ${name}`);
          routes[2] = `/${pathnames.slice(0, index + 1).join('/')}`;
          break;
      }
    });
  } else if (pathnames.includes('perfil')) {
    breadcrumbItems.push(`Perfil`);
    routes[0] = `/${pathnames.slice(0, 0 + 1).join('/')}`;
  } else if (pathnames.includes('construtor')) {
    breadcrumbItems.push(`Construtor de Pesquisas`);
    routes[0] = `/${pathnames.slice(0, 0 + 1).join('/')}`;
  }

  if (
    pathnames.includes('entrar') ||
    pathnames.includes('esquecisenha') ||
    pathnames.includes('resetsenha') ||
    pathnames.length === 0 ||
    pathnames.includes('responder')
  ) {
    return <div />;
  }

  return (
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => history.push('/')}>
        Painel
      </Breadcrumb.Item>

      {breadcrumbItems.map((name, index) => {
        if (breadcrumbItems.length === index + 1) {
          return (
            <Breadcrumb.Item
              active
              key={index}
              onClick={() => history.push(routes[index])}
            >
              {name}
            </Breadcrumb.Item>
          );
        }
        return (
          <Breadcrumb.Item
            key={index}
            onClick={() => history.push(routes[index])}
          >
            {name}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default withRouter(Breadcrumbs);
