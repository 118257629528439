import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';

import EmptyResults from '../empty/EmptyResults';

const ProductCard = ({ product, researches }) => {
  const researchQuant = researches.filter(
    research => research.type === product.type,
  );

  return (
    <Card className="m-2">
      {product.logo && (
        <div className="text-center">
          <Card.Img
            style={{ height: '60px', width: 'auto' }}
            className="mt-2"
            variant="top"
            src={product.logo}
            alt={product.name}
          />
        </div>
      )}
      <Card.Body>
        {!product.logo && (
          <Card.Title style={{ height: '60px' }} className="text-center mb-2">
            {product.name}
          </Card.Title>
        )}
        {/* <Card.Text>{product.description}</Card.Text> */}
        {researchQuant.length > 0 && (
          <Card.Text className="px-2 py-3 mb-4 text-center">
            {`Temos ${researchQuant.length} ${
              researchQuant.length > 1
                ? 'pesquisas respondidas.'
                : 'pesquisa respondida.'
            }`}
          </Card.Text>
        )}
        {researchQuant.length === 0 && (
          <EmptyResults message="Nenhuma pesquisa respondida." />
        )}
        <div className="text-right">
          <Link to={`/produtos/${product.type}/pesquisas`}>
            <Button variant="primary">Entrar</Button>
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
