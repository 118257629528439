import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Jumbotron } from 'react-bootstrap';
import jwtDecode from 'jwt-decode';

import { setLoggedUser, selectLoggedUser } from './usersSlice';

import errorHandler from '../../app/error';
import { get } from '../../app/axios';
import {
  selectDateLocale,
  selectDateOptions,
} from '../../components/date/dateSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import EmptyResults from '../../components/empty/EmptyResults';

const User = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectLoggedUser);
  const locale = useSelector(selectDateLocale);
  const options = useSelector(selectDateOptions);

  const getUser = useCallback(async () => {
    const token = window.localStorage.getItem('token');

    if (token) {
      dispatch(increaseLoadingState());
      const loggedUserId = jwtDecode(token)._id;

      try {
        const response = await get(`/users/${loggedUserId}`);
        if (response.message) {
          response.context = 'recuperar as informações do usuário';
          errorHandler(response);
        } else {
          dispatch(setLoggedUser(response));
        }
      } catch (error) {
        error.context = 'recuperar as informações do usuário';
        errorHandler(error);
      }
      dispatch(decreaseLoadingState());
    }
  }, [dispatch]);

  useEffect(() => {
    getUser().then();
  }, [getUser]);

  return (
    <Container>
      {user && user._id && (
        <Jumbotron>
          <h3>{user.username}</h3>
          <p>
            <strong>ID: </strong>
            <span>{user._id}</span>
          </p>
          <p>
            <strong>Email: </strong>
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </p>
          <p>
            <strong>Criado em: </strong>
            <span>
              {new Date(user.created_at).toLocaleDateString(locale, options)}
            </span>
          </p>
          <p>
            <strong>Última alteração: </strong>
            <span>
              {new Date(user.updated_at).toLocaleDateString(locale, options)}
            </span>
          </p>
        </Jumbotron>
      )}
      {!user._id && (
        <EmptyResults
          message="Falha ao recuperar suas informações."
          redirectLink="/entrar"
          redirectAction="Logue novamente"
        />
      )}
    </Container>
  );
};

export default User;
