import { createSlice } from '@reduxjs/toolkit';

export const dateSlice = createSlice({
  name: 'date',
  initialState: {
    locale: 'pt-br',
    options: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
  },
});

export const selectDateLocale = state => state.date.locale;
export const selectDateOptions = state => state.date.options;

export default dateSlice.reducer;
