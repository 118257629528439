import { createSlice } from '@reduxjs/toolkit';

export const answerSlice = createSlice({
  name: 'answer',
  initialState: {
    answer: {},
    quest: {},
  },
  reducers: {
    setQuest: (state, action) => {
      state.quest = action.payload;
    },
    clearQuest: state => {
      state.quest = [];
    },
    setAnswer: (state, action) => {
      state.answer = action.payload;
    },
    clearAnswer: state => {
      state.answer = [];
    },
  },
});

export const {
  setAnswer,
  clearAnswer,
  setQuest,
  clearQuest,
} = answerSlice.actions;

export const selectAnswer = state => state.answer.answer;
export const selectQuest = state => state.answer.quest;

export default answerSlice.reducer;
