import React from 'react';
import { Container } from 'react-bootstrap';

import EmptyResults from '../../components/empty/EmptyResults';

const NotFound = () => {
  return (
    <Container>
      <EmptyResults
        title="Ops"
        message="Infelizmente a página que você digitou não existe. Digite novamente ou volte para o Painel de Produtos."
        redirectLink="/"
        redirectAction="Voltar para o Painel de Produtos"
      />
    </Container>
  );
};

export default NotFound;
