import React, { useState, useEffect } from 'react';

const Checkbox = ({ checked, onChange, label, style, value }) => {
  const [check, setCheck] = useState(checked);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <>
      <div className={style.checkbox}>
        <button
          type="button"
          className={style.border}
          onClick={() => {
            setCheck(!check);
            onChange({ target: { value } });
          }}
          onKeyDown={event => {
            if (event.keycode === 13) {
              setCheck(!check);
              onChange({ target: { value } });
            }
          }}
        >
          <div className={`${check ? style.checked : ''}`} />
        </button>
        <div className={style.label}>{label}</div>
      </div>
    </>
  );
};

export default Checkbox;
