import React from 'react';
import { useSelector } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import { isLoading } from './loadingSlice';

import styles from '../../sass/components/Loading.module.scss';

const Loading = () => {
  const spinner = (
    <div className={styles.spinnerContainer}>
      <Spinner
        variant="primary"
        animation="border"
        role="status"
        className={styles.spinnerModule}
      />
    </div>
  );

  return useSelector(isLoading) !== 0 ? spinner : <div />;
};

export default Loading;
