import { createSlice } from '@reduxjs/toolkit';
import _cloneDeep from 'lodash-es/cloneDeep';

import talentflow from '../../assets/images/talentflow.png';
import privacyflow from '../../assets/images/privacyflow.png';
import onway from '../../assets/images/onway.png';
import botgrade from '../../assets/images/botgrade.png';
import veritascode from '../../assets/images/veritascode.png';

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
  },
  reducers: {
    setProducts: (state, action) => {
      const payload = _cloneDeep(action.payload);

      payload.map(product => {
        // product.type = product._id;
        if (product.name === 'Talentflow') {
          // product._id = 'talentflow';
          product.type = 'talentflow';
          product.logo = talentflow;
          product.site = 'https://talentflow.ai/';
        } else if (product.name === 'Privacyflow') {
          // product._id = 'privacyflow';
          product.type = 'privacyflow';
          product.logo = privacyflow;
          product.site = 'https://privacyflow.ai/';
        } else if (product.name === 'OnWay') {
          // product._id = 'onway';
          product.type = 'onway';
          product.logo = onway;
          product.site = 'http://www.onwayhealth.com/';
        } else if (product.name === 'BotGrade') {
          // product._id = 'botgrade';
          product.type = 'botgrade';
          product.logo = botgrade;
          product.site =
            'https://chatbot.certsys.io/botgrade-chatbot-analytics/';
        } else if (product.name === 'Veritas Code') {
          // product._id = 'veritascode';
          product.type = 'veritascode';
          product.logo = veritascode;
        } else if (product.name === 'Academy') {
          // product._id = 'talentflow';
          product.type = 'talentflow';
          product.site =
            'https://chatbot.certsys.io/curadoria-certsys-academy/';
        }
        return product;
      });

      state.products = payload;
    },
    clearProducts: state => {
      state.products = [];
    },
  },
});

export const { setProducts, clearProducts } = productsSlice.actions;

export const selectProducts = state => state.products.products;

export default productsSlice.reducer;

// [
//   {
//     _id: 'privacyflow',
//     name: 'Privacyflow',
//     description: 'O Privacyflow é uma central inteligente de comunicação entre a empresa e o cliente, que facilita o processo de conformidade com a LGPD.',
//     logo: privacyflow,
//     site: 'https://privacyflow.ai/',
//   },
//   {
//     _id: 'talentflow',
//     name: 'Talentflow',
//     description: 'A Talentflow é uma plataforma que facilita o encontro do cleinte com um time ideal, por meio de um processo de alocação simples e transparente',
//     logo: talentflow,
//     site: 'https://talentflow.ai/',
//   },
//   {
//     _id: 'onway',
//     name: 'OnWay',
//     description: 'Certsys OnWay.',
//     logo: onway,
//     site: 'http://www.onwayhealth.com/',
//   },
//   {
//     _id: 'botgrade',
//     name: 'BotGrade',
//     description: 'Motor de testes automatizados para as intenções dentro do Watson Assistant.',
//     logo: botgrade,
//     site: 'https://chatbot.certsys.io/botgrade-chatbot-analytics/',
//   },
//   {
//     _id: 'veritascode',
//     name: 'Veritas Code',
//     description: 'Veritas Code.',
//     logo: veritascode,
//     site: '',
//   },
//   {
//     _id: 'academy',
//     name: 'Academy',
//     description: 'Certsys Academy.',
//     logo: '',
//     site: 'https://chatbot.certsys.io/curadoria-certsys-academy/',
//   },
// ],
