import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {
  Accordion,
  Container,
  Jumbotron,
  Button,
  Form,
  Card,
} from 'react-bootstrap';
import _cloneDeep from 'lodash-es/cloneDeep';

import {
  setResearches,
  clearResearches,
  // selectResearches,
} from '../researches/researchesSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import {
  selectDateLocale,
  selectDateOptions,
} from '../../components/date/dateSlice';
import { get, put, post } from '../../app/axios';
import { selectClientInterface } from '../clients/clientsSlice';
import { selectProducts } from '../products/productsSlice';
import errorHandler from '../../app/error';
import { displayNotification } from '../../components/notifications/notificationSlice';
// import Researches from '../researches/Researches';
import Research from '../researches/Research';
import EmptyResults from '../../components/empty/EmptyResults';

const ConstructorUnity = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const [page] = useState(1); // setPage
  // const [totalPages, setTotalPage] = useState(0);
  const [limit] = useState(20);
  const [textOptions] = useState([1, 2, 3, 5, 6]);
  const [multiOptions] = useState([11, 12, 13]);
  const [captureLeadOpts] = useState([
    { _id: 1, name: 'Capturar o lead por um formulário inicial obrigatório' },
    { _id: 2, name: 'Capturar o lead por perguntas obrigatórias' },
    { _id: 3, name: 'Não capturar o lead' },
  ]);
  const [sectionOrientationOpts] = useState([
    { _id: 1, name: 'Sessões na mesma página' },
    { _id: 2, name: 'Uma sessão por página' },
  ]);
  const [questionOrientationOpts] = useState([
    { _id: 1, name: 'Perguntas na mesma página' },
    { _id: 2, name: 'Uma pergunta por página' },
  ]);
  const [isNew, setIsNew] = useState(false);
  const [constructorUnity, setConstructorUnity] = useState({
    name: '',
    version: '',
    type: -1,
    introMessage: '',
    thanksMessage: '',
    captureLead: -1,
    sectionOrientation: -1,
    sections: [],
  });

  const { constructorId } = useParams();

  const locale = useSelector(selectDateLocale);
  const options = useSelector(selectDateOptions);
  // const researches = useSelector(selectResearches);
  const products = useSelector(selectProducts);
  const clientInterface = useSelector(selectClientInterface);

  // const { location } = props;
  // const { pathname } = location;
  // const pathnames = pathname.split('/').filter(x => x);
  // const previousRoute = pathname.replace(`${pathname}`, `/${pathnames[0]}`);

  useEffect(() => {
    if (constructorId === 'novapesquisa') {
      setIsNew(true);
    }
  }, [constructorId]);

  const getResearches = useCallback(async () => {
    if (constructorId !== 'novapesquisa') {
      dispatch(clearResearches());
      dispatch(increaseLoadingState());

      try {
        const response = await get(
          `/quests/${constructorId}?limit=${limit}&page=${page}`,
        );
        if (response.message) {
          response.context = 'recuperar as informações da pesquisa construída';
          errorHandler(response);
        } else {
          setConstructorUnity(response);
          // setConstructorUnity(response.quest);
          if (
            response.researches &&
            response.researches.list &&
            response.researches.list.length > 0
          ) {
            // response.researches.list.map(research => research.clientId = response.client);
            dispatch(setResearches(response.researches.list));
            // setTotalPage(response.researches.totalPages);
          }
        }
      } catch (error) {
        error.context = 'recuperar as informações da pesquisa construída';
        errorHandler(error);
      }
      dispatch(decreaseLoadingState());
    }
  }, [constructorId, page, limit, dispatch]);

  useEffect(() => {
    getResearches().then();
  }, [getResearches]);

  const handleSubmit = async event => {
    event.preventDefault();
    let resp = {};

    try {
      dispatch(increaseLoadingState());

      if (isNew) {
        resp = await post('/quests', constructorUnity);
      } else {
        resp = await put(`/quests/${constructorId}`, constructorUnity);
      }

      if (resp.message) {
        resp.context = 'salvar a pesquisa construída';
        errorHandler(resp);
      } else {
        dispatch(
          displayNotification({
            title: 'Sucesso!',
            text: 'Pesquisa salva.',
            variant: 'success',
          }),
        );
        getResearches().then();
      }

      dispatch(decreaseLoadingState());
    } catch (error) {
      error.context = 'salvar a pesquisa construída';
      errorHandler(error);
      dispatch(decreaseLoadingState());
    }
  };

  const newGroup = (secId, queId) => {
    const constructorUnityClone = _cloneDeep(constructorUnity);

    if (queId || queId === 0) {
      constructorUnityClone.sections[secId].questions[
        queId
      ].responseOptions.push({
        name: '',
        explanation: '',
        weight: 0,
      });
    } else if (secId || secId === 0) {
      constructorUnityClone.sections[secId].questions.push({
        question: '',
        explanation: '',
        responseType: '',
        responseOptions: [],
        responseLabel: '', // Resposta
        responsePlaceholder: '',
        responseTarget: '',
        allowDontKnow: false,
        dontKnowLabel: '', // Não sei
        allowJustification: false,
        justificationLabel: '', // Mais detalhes
        justificationPlaceholder: '', // Compartilhe mais detalhes deste ponto conosco. Não é obrigatório mas nos ajudará bastante
        allowAttachment: false,
        attachmentLabel: '',
        attachmentPlaceholder: '',
        required: true,
        requiredLabel: '*',
        allowOther: false,
        otherOption: '',
        otherLabel: '',
        otherPlaceholder: '',
        minLength: 0,
        maxLength: 0,
        mask: '',
      });
    } else {
      constructorUnityClone.sections.push({
        name: '',
        description: '',
        questions: [],
        questionOrientation: -1,
      });
    }

    setConstructorUnity(constructorUnityClone);
  };

  const changeField = (value, target, secId, queId, optId) => {
    const constructorUnityClone = _cloneDeep(constructorUnity);

    if (optId || optId === 0) {
      constructorUnityClone.sections[secId].questions[queId].responseOptions[
        optId
      ][target] = value;
    } else if (queId || queId === 0) {
      constructorUnityClone.sections[secId].questions[queId][target] = value;
    } else if (secId || secId === 0) {
      constructorUnityClone.sections[secId][target] = value;
    } else {
      constructorUnityClone[target] = value;
    }

    setConstructorUnity(constructorUnityClone);
  };

  const getPreviousQuestionLabels = (target, secId, queId) => {
    const constructorUnityClone = _cloneDeep(constructorUnity);

    if (target === 'thanksMessage') {
      if (
        !constructorUnityClone.sections ||
        constructorUnityClone.sections.length === 0
      ) {
        return [];
      }

      return constructorUnityClone.sections.reduce((acc, curr) => {
        if (curr.questions && curr.questions.length > 0) {
          curr.questions.forEach(question => {
            if (question.responseLabel) {
              acc.push(question.responseLabel);
            }
          });
        }
        return acc;
      }, []);
    }

    if (
      target === 'question' &&
      (secId || secId === 0) &&
      (queId || queId === 0)
    ) {
      if (
        !constructorUnityClone.sections ||
        constructorUnityClone.sections.length === 0
      ) {
        return [];
      }

      return constructorUnityClone.sections.reduce((acc, curr, secIndex) => {
        if (curr.questions && curr.questions.length > 0 && secIndex <= secId) {
          curr.questions.forEach((question, queIndex) => {
            if (question.responseLabel && queIndex < queId) {
              acc.push(question.responseLabel);
            }
          });
        }
        return acc;
      }, []);
    }

    return [];
  };

  const mentionLabel = (value, target, secId, queId) => {
    const constructorUnityClone = _cloneDeep(constructorUnity);
    if (target === 'thanksMessage') {
      constructorUnityClone[target] += ` @${value}@`;
    }
    if (
      target === 'question' &&
      (secId || secId === 0) &&
      (queId || queId === 0)
    ) {
      constructorUnityClone.sections[secId].questions[queId][
        target
      ] += ` <${value}>`;
    }
    setConstructorUnity(constructorUnityClone);
  };

  // const handleChangePage = page => {
  //   setPage(page);
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // };

  const handleTextMention = string => {
    // eslint-disable-next-line no-useless-escape
    const fields = string.match(/\@(.*?)\@/g);
    if (!fields) {
      return string;
    }
    let text = `<span>${_cloneDeep(string)}</span>`;

    fields.forEach(field => {
      text = text.replace(
        field,
        `</span><span class="text-warning">${field}</span><span>`,
      );
    });

    return text;
  };

  const createCaptureLeadFields = () => {
    const constructorUnityClone = _cloneDeep(constructorUnity);

    // TODO:
    // Checks if lead capture fields are already created

    if (constructorUnityClone.sections.length === 0) {
      constructorUnityClone.sections.push({
        name: '',
        description: '',
        questions: [],
        questionOrientation: -1,
      });
    }

    const lastSec = constructorUnityClone.sections[0];

    clientInterface.forEach(clientInt => {
      lastSec.questions.push({
        question: clientInt.question,
        responseType: clientInt.type,
        responseTarget: clientInt.target,
        responseLabel: clientInt.responseLabel,
        responsePlaceholder: clientInt.responsePlaceholder,
        responseOptions: clientInt.responseOptions || [],
        allowOther: clientInt.allowOther || false,
        otherOption: clientInt.otherOption,
        otherLabel: clientInt.otherLabel,
        otherPlaceholder: clientInt.otherPlaceholder,
        explanation: '',
        allowDontKnow: false,
        dontKnowLabel: '',
        allowJustification: false,
        justificationLabel: '',
        justificationPlaceholder: '',
        allowAttachment: false,
        attachmentLabel: '',
        attachmentPlaceholder: '',
        required: true,
        requiredLabel: '*',
        minLength: 0,
        maxLength: 0,
        mask: '',
      });
    });

    setConstructorUnity(constructorUnityClone);
  };

  const deleteGroup = (secId, queId, optId) => {
    const constructorUnityClone = _cloneDeep(constructorUnity);

    if (optId || optId === 0) {
      constructorUnityClone.sections[secId].questions[
        queId
      ].responseOptions.splice(optId, 1);
    } else if (queId || queId === 0) {
      constructorUnityClone.sections[secId].questions.splice(queId, 1);
    } else if (secId || secId === 0) {
      constructorUnityClone.sections.splice(secId, 1);
    } else {
      dispatch(
        displayNotification({
          title: 'Atenção!',
          text: 'Nenhum grupo apagado.',
          variant: 'warning',
        }),
      );
    }

    setConstructorUnity(constructorUnityClone);
  };

  return (
    <div>
      <Switch>
        <Route
          path="/construtor/:constructorId/pesquisas/:researchId"
          component={Research}
        />
        <Route path={match.path}>
          {constructorUnity && (
            <Container className="mb-4">
              <Form onSubmit={handleSubmit}>
                <Jumbotron>
                  <h1 className="text-truncate">{`Pesquisa: ${constructorUnity.name}`}</h1>
                  {constructorUnity._id && (
                    <p>
                      <strong>ID: </strong>
                      <span>{constructorUnity._id}</span>
                    </p>
                  )}
                  {constructorUnity._id && constructorUnity.type && (
                    <p>
                      <Link
                        target="_blank"
                        to={`/responder/${constructorUnity.type}/${constructorUnity._id}`}
                      >
                        <Button variant="primary">Preview</Button>
                      </Link>
                    </p>
                  )}

                  <Form.Group controlId="formName">
                    <Form.Label>Nome da Pesquisa</Form.Label>
                    <span className="ml-1 text-danger">*</span>
                    <Form.Control
                      type="text"
                      required
                      placeholder="Informe o nome da pesquisa"
                      onChange={e => changeField(e.target.value, 'name')}
                      value={constructorUnity.name}
                    />
                  </Form.Group>

                  <Form.Group controlId="formVersion">
                    <Form.Label>Versão</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe a versão da pesquisa"
                      onChange={e => changeField(e.target.value, 'version')}
                      value={constructorUnity.version}
                    />
                  </Form.Group>

                  <Form.Group controlId="formProduct">
                    <Form.Label>Produto</Form.Label>
                    <span className="ml-1 text-danger">*</span>
                    <Form.Control
                      as="select"
                      required
                      value={constructorUnity.type || -1}
                      onChange={e => changeField(e.target.value, 'type')}
                    >
                      <option disabled value={-1} key={-1}>
                        Selecione o produto
                      </option>
                      {products.map((productItem, index) => {
                        return (
                          <option value={productItem._id} key={index}>
                            {productItem.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formCaptureLead">
                    <Form.Label>Captura de Lead</Form.Label>
                    <span className="ml-1 text-danger">*</span>
                    <Form.Control
                      as="select"
                      required
                      value={constructorUnity.captureLead || -1}
                      onChange={e =>
                        changeField(parseInt(e.target.value, 10), 'captureLead')
                      }
                    >
                      <option disabled value={-1} key={-1}>
                        Selecione a forma de captura do Lead
                      </option>
                      {captureLeadOpts.map((opt, index) => {
                        return (
                          <option value={opt._id} key={index}>
                            {opt.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>

                  {(constructorUnity.captureLead === 1 ||
                    constructorUnity.captureLead === 2) && (
                    <Button
                      id="formCaptureLeadButton"
                      type="button"
                      variant="primary"
                      className="m-2"
                      onClick={() => createCaptureLeadFields()}
                    >
                      Criar Campos de Captura
                    </Button>
                  )}

                  <Form.Group controlId="formSessionOrientation">
                    <Form.Label>Orientação das sessões</Form.Label>
                    <span className="ml-1 text-danger">*</span>
                    <Form.Control
                      as="select"
                      required
                      value={constructorUnity.sectionOrientation || -1}
                      onChange={e =>
                        changeField(
                          parseInt(e.target.value, 10),
                          'sectionOrientation',
                        )
                      }
                    >
                      <option disabled value={-1} key={-1}>
                        Selecione a orientação das sessões
                      </option>
                      {sectionOrientationOpts.map((opt, index) => {
                        return (
                          <option value={opt._id} key={index}>
                            {opt.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formIntroMessage">
                    <Form.Label>Mensagem de Introdução</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Digite a mensagem de introdução, a primeira tela que o cliente vai visualizar."
                      onChange={e =>
                        changeField(e.target.value, 'introMessage')
                      }
                      value={constructorUnity.introMessage}
                    />
                  </Form.Group>

                  {constructorUnity.created_at && (
                    <p>
                      <strong>Criado em: </strong>
                      <span>
                        {new Date(
                          constructorUnity.created_at,
                        ).toLocaleDateString(locale, options)}
                      </span>
                    </p>
                  )}
                  {constructorUnity.updated_at && (
                    <p>
                      <strong>Última alteração: </strong>
                      <span>
                        {new Date(
                          constructorUnity.updated_at,
                        ).toLocaleDateString(locale, options)}
                      </span>
                    </p>
                  )}
                </Jumbotron>

                <Accordion defaultActiveKey="0">
                  {constructorUnity.sections &&
                    constructorUnity.sections.length > 0 &&
                    constructorUnity.sections.map((section, secId) => (
                      <Card key={`section-${secId}`}>
                        <Button
                          id={`formSection${secId}DeleteButton`}
                          size="sm"
                          type="button"
                          variant="outline-danger"
                          className="close-button"
                          onClick={() => deleteGroup(secId)}
                        >
                          X
                        </Button>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={`${secId}`}
                        >
                          <h2 className="text-truncate text-left">
                            {`Sessão: ${handleTextMention(section.name)}`}
                          </h2>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`${secId}`}>
                          <Card.Body>
                            <Form.Group controlId={`formSection${secId}Name`}>
                              <Form.Label>Nome da Sessão</Form.Label>

                              {/* TODO: Make this a new mention component */}
                              {getPreviousQuestionLabels('name', secId).length >
                                0 && (
                                <Form.Control
                                  as="select"
                                  size="sm"
                                  value={-1}
                                  className="ml-2 d-inline-block w-auto"
                                  onChange={e =>
                                    mentionLabel(e.target.value, 'name', secId)
                                  }
                                >
                                  <option disabled value={-1} key={-1}>
                                    Mencione uma resposta
                                  </option>
                                  {getPreviousQuestionLabels('name', secId).map(
                                    (label, index) => {
                                      return (
                                        <option value={label} key={index}>
                                          {label}
                                        </option>
                                      );
                                    },
                                  )}
                                </Form.Control>
                              )}

                              <Form.Control
                                type="text"
                                placeholder="Informe o nome desta sessão"
                                onChange={e =>
                                  changeField(e.target.value, 'name', secId)
                                }
                                value={section.name}
                              />
                            </Form.Group>

                            <Form.Group
                              controlId={`formSection${secId}Description`}
                            >
                              <Form.Label>Descrição</Form.Label>

                              {/* TODO: Make this a new mention component */}
                              {getPreviousQuestionLabels('description', secId)
                                .length > 0 && (
                                <Form.Control
                                  as="select"
                                  size="sm"
                                  value={-1}
                                  className="ml-2 d-inline-block w-auto"
                                  onChange={e =>
                                    mentionLabel(
                                      e.target.value,
                                      'description',
                                      secId,
                                    )
                                  }
                                >
                                  <option disabled value={-1} key={-1}>
                                    Mencione uma resposta
                                  </option>
                                  {getPreviousQuestionLabels(
                                    'description',
                                    secId,
                                  ).map((label, index) => {
                                    return (
                                      <option value={label} key={index}>
                                        {label}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                              )}

                              <Form.Control
                                type="text"
                                placeholder="Informe a descrição desta sessão"
                                onChange={e =>
                                  changeField(
                                    e.target.value,
                                    'description',
                                    secId,
                                  )
                                }
                                value={section.description}
                              />
                            </Form.Group>

                            {/* {constructorUnity.sectionOrientation === 2 && */}
                            <Form.Group
                              controlId={`formSection${secId}QuestionOrientation`}
                            >
                              <Form.Label>Orientação das perguntas</Form.Label>
                              <span className="ml-1 text-danger">*</span>
                              <Form.Control
                                as="select"
                                required
                                value={
                                  constructorUnity.questionOrientation || -1
                                }
                                onChange={e =>
                                  changeField(
                                    parseInt(e.target.value, 10),
                                    'questionOrientation',
                                    secId,
                                  )
                                }
                              >
                                <option disabled value={-1} key={-1}>
                                  Selecione a orientação das perguntas desta
                                  sessão
                                </option>
                                {questionOrientationOpts.map((opt, index) => {
                                  return (
                                    <option value={opt._id} key={index}>
                                      {opt.name}
                                    </option>
                                  );
                                })}
                              </Form.Control>
                            </Form.Group>
                            {/* } */}

                            <Accordion defaultActiveKey="0">
                              {section.questions.length > 0 &&
                                section.questions.map((question, queId) => (
                                  <Card key={`question-${queId}`}>
                                    <Button
                                      id={`formSection${secId}Question${queId}DeleteButton`}
                                      size="sm"
                                      type="button"
                                      variant="outline-danger"
                                      className="close-button"
                                      onClick={() => deleteGroup(secId, queId)}
                                    >
                                      X
                                    </Button>
                                    <Accordion.Toggle
                                      as={Button}
                                      variant="link"
                                      eventKey={`${queId}`}
                                    >
                                      <h4
                                        className="text-truncate text-left"
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                          __html: `Pergunta: ${handleTextMention(
                                            question.question,
                                          )}`,
                                        }}
                                      />
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={`${queId}`}>
                                      <Card.Body>
                                        <Form.Group
                                          controlId={`formSection${secId}Question${queId}Question`}
                                        >
                                          <Form.Label>Pergunta</Form.Label>
                                          <span className="ml-1 text-danger">
                                            *
                                          </span>

                                          {/* TODO: Make this a new mention component */}
                                          {getPreviousQuestionLabels(
                                            'question',
                                            secId,
                                            queId,
                                          ).length > 0 && (
                                            <Form.Control
                                              as="select"
                                              size="sm"
                                              value={-1}
                                              className="ml-2 d-inline-block w-auto"
                                              onChange={e =>
                                                mentionLabel(
                                                  e.target.value,
                                                  'question',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                            >
                                              <option
                                                disabled
                                                value={-1}
                                                key={-1}
                                              >
                                                Mencione uma resposta
                                              </option>
                                              {getPreviousQuestionLabels(
                                                'question',
                                                secId,
                                                queId,
                                              ).map((label, index) => {
                                                return (
                                                  <option
                                                    value={label}
                                                    key={index}
                                                  >
                                                    {label}
                                                  </option>
                                                );
                                              })}
                                            </Form.Control>
                                          )}

                                          <Form.Control
                                            type="text"
                                            required
                                            placeholder="Informe a pergunta"
                                            onChange={e =>
                                              changeField(
                                                e.target.value,
                                                'question',
                                                secId,
                                                queId,
                                              )
                                            }
                                            value={question.question}
                                          />
                                        </Form.Group>

                                        <Form.Group
                                          controlId={`formSection${secId}Question${queId}Explanation`}
                                        >
                                          <Form.Label>Explicação</Form.Label>

                                          {/* TODO: Make this a new mention component */}
                                          {getPreviousQuestionLabels(
                                            'explanation',
                                            secId,
                                            queId,
                                          ).length > 0 && (
                                            <Form.Control
                                              as="select"
                                              size="sm"
                                              value={-1}
                                              className="ml-2 d-inline-block w-auto"
                                              onChange={e =>
                                                mentionLabel(
                                                  e.target.value,
                                                  'explanation',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                            >
                                              <option
                                                disabled
                                                value={-1}
                                                key={-1}
                                              >
                                                Mencione uma resposta
                                              </option>
                                              {getPreviousQuestionLabels(
                                                'explanation',
                                                secId,
                                                queId,
                                              ).map((label, index) => {
                                                return (
                                                  <option
                                                    value={label}
                                                    key={index}
                                                  >
                                                    {label}
                                                  </option>
                                                );
                                              })}
                                            </Form.Control>
                                          )}

                                          <Form.Control
                                            type="text"
                                            placeholder="Informe a explicação da pergunta"
                                            onChange={e =>
                                              changeField(
                                                e.target.value,
                                                'explanation',
                                                secId,
                                                queId,
                                              )
                                            }
                                            value={question.explanation}
                                          />
                                        </Form.Group>

                                        <Form.Group
                                          controlId={`formSection${secId}Question${queId}AllowDontKnow`}
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            label="Permitir resposta 'Não Sei'?"
                                            onChange={() =>
                                              changeField(
                                                !question.allowDontKnow,
                                                'allowDontKnow',
                                                secId,
                                                queId,
                                              )
                                            }
                                            checked={question.allowDontKnow}
                                          />
                                        </Form.Group>
                                        {question.allowDontKnow && (
                                          <Form.Group
                                            controlId={`formSection${secId}Question${queId}DontKnowLabel`}
                                          >
                                            <Form.Label>
                                              Label do campo &quot;Não sei&quot;
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              placeholder="Informe a label do campo"
                                              onChange={e =>
                                                changeField(
                                                  e.target.value,
                                                  'dontKnowLabel',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                              value={question.dontKnowLabel}
                                            />
                                          </Form.Group>
                                        )}

                                        <Form.Group
                                          controlId={`formSection${secId}Question${queId}AllowJustification`}
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            label="Permitir justificativa?"
                                            onChange={() =>
                                              changeField(
                                                !question.allowJustification,
                                                'allowJustification',
                                                secId,
                                                queId,
                                              )
                                            }
                                            checked={
                                              question.allowJustification
                                            }
                                          />
                                        </Form.Group>
                                        {question.allowJustification && (
                                          <Form.Group
                                            controlId={`formSection${secId}Question${queId}JustificationLabel`}
                                          >
                                            <Form.Label>
                                              Label do campo
                                              &ldquo;Justificativa&rdquo;
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              placeholder="Informe a label do campo"
                                              onChange={e =>
                                                changeField(
                                                  e.target.value,
                                                  'justificationLabel',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                              value={
                                                question.justificationLabel
                                              }
                                            />
                                          </Form.Group>
                                        )}
                                        {question.allowJustification && (
                                          <Form.Group
                                            controlId={`formSection${secId}Question${queId}JustificationPlaceholder`}
                                          >
                                            <Form.Label>
                                              Placeholder do campo
                                              &ldquo;Justificativa&rdquo;
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              placeholder="Informe o placeholder do campo"
                                              onChange={e =>
                                                changeField(
                                                  e.target.value,
                                                  'justificationPlaceholder',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                              value={
                                                question.justificationPlaceholder
                                              }
                                            />
                                          </Form.Group>
                                        )}

                                        <Form.Group
                                          controlId={`formSection${secId}Question${queId}Required`}
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            label="Pergunta Obrigratória?"
                                            onChange={() =>
                                              changeField(
                                                !question.required,
                                                'required',
                                                secId,
                                                queId,
                                              )
                                            }
                                            checked={question.required}
                                          />
                                        </Form.Group>
                                        {question.required && (
                                          <Form.Group
                                            controlId={`formSection${secId}Question${queId}RequiredLabel`}
                                          >
                                            <Form.Label>
                                              Indicador de campo obrigatório
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              placeholder="Informe o que indica que a pergunta é obrigatória"
                                              onChange={e =>
                                                changeField(
                                                  e.target.value,
                                                  'requiredLabel',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                              value={question.requiredLabel}
                                            />
                                          </Form.Group>
                                        )}

                                        <Form.Group
                                          controlId={`formSection${secId}Question${queId}AllowAttachment`}
                                        >
                                          <Form.Check
                                            type="checkbox"
                                            label="Permitir anexo?"
                                            onChange={() =>
                                              changeField(
                                                !question.allowAttachment,
                                                'allowAttachment',
                                                secId,
                                                queId,
                                              )
                                            }
                                            checked={question.allowAttachment}
                                          />
                                        </Form.Group>
                                        {question.allowAttachment && (
                                          <Form.Group
                                            controlId={`formSection${secId}Question${queId}AttachmentLabel`}
                                          >
                                            <Form.Label>
                                              Label do campo &ldquo;Anexo&rdquo;
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              placeholder="Informe a label do campo"
                                              onChange={e =>
                                                changeField(
                                                  e.target.value,
                                                  'attachmentLabel',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                              value={question.attachmentLabel}
                                            />
                                          </Form.Group>
                                        )}
                                        {question.allowAttachment && (
                                          <Form.Group
                                            controlId={`formSection${secId}Question${queId}AttachmentPlaceholder`}
                                          >
                                            <Form.Label>
                                              Placeholder do campo
                                              &ldquo;Anexo&rdquo;
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              placeholder="Informe o placeholder do campo"
                                              onChange={e =>
                                                changeField(
                                                  e.target.value,
                                                  'attachmentPlaceholder',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                              value={
                                                question.attachmentPlaceholder
                                              }
                                            />
                                          </Form.Group>
                                        )}

                                        <Form.Group
                                          controlId={`formSection${secId}Question${queId}ResponseLabel`}
                                        >
                                          <Form.Label>
                                            Label do campo
                                            &ldquo;Resposta&rdquo;
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Informe a label do campo"
                                            onChange={e =>
                                              changeField(
                                                e.target.value,
                                                'responseLabel',
                                                secId,
                                                queId,
                                              )
                                            }
                                            value={question.responseLabel}
                                          />
                                        </Form.Group>

                                        <Form.Group
                                          controlId={`formSection${secId}Question${queId}ResponsePlaceholder`}
                                        >
                                          <Form.Label>
                                            Placeholder do campo
                                            &ldquo;Resposta&rdquo;
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            placeholder="Informe o placeholder do campo"
                                            onChange={e =>
                                              changeField(
                                                e.target.value,
                                                'responsePlaceholder',
                                                secId,
                                                queId,
                                              )
                                            }
                                            value={question.responsePlaceholder}
                                          />
                                        </Form.Group>

                                        <Form.Group
                                          controlId={`formSection${secId}Question${queId}ResponseTarget`}
                                        >
                                          <Form.Label>
                                            Link com a Captura do lead
                                          </Form.Label>
                                          <div>
                                            <small>
                                              Este campo será salvo tanto na
                                              pesquisa como nas informações do
                                              lead.
                                            </small>
                                          </div>
                                          <Form.Control
                                            as="select"
                                            required
                                            value={
                                              question.responseTarget || -1
                                            }
                                            onChange={e =>
                                              changeField(
                                                e.target.value,
                                                'responseTarget',
                                                secId,
                                                queId,
                                              )
                                            }
                                          >
                                            <option
                                              disabled
                                              value={-1}
                                              key={-1}
                                            >
                                              Selecione o campo
                                            </option>
                                            {clientInterface.map(
                                              (clientInt, index) => {
                                                return (
                                                  <option
                                                    value={clientInt.target}
                                                    key={index}
                                                  >
                                                    {clientInt.responseLabel}
                                                  </option>
                                                );
                                              },
                                            )}
                                          </Form.Control>
                                        </Form.Group>

                                        <Form.Group
                                          controlId={`formSection${secId}Question${queId}ResponseType`}
                                        >
                                          <Form.Label>
                                            Tipo da Resposta
                                          </Form.Label>
                                          <span className="ml-1 text-danger">
                                            *
                                          </span>
                                          <Form.Control
                                            as="select"
                                            required
                                            value={question.responseType || -1}
                                            onChange={e =>
                                              changeField(
                                                parseInt(e.target.value, 10),
                                                'responseType',
                                                secId,
                                                queId,
                                              )
                                            }
                                          >
                                            <option
                                              disabled
                                              value={-1}
                                              key={-1}
                                            >
                                              Selecione o tipo da resposta
                                              esperada
                                            </option>
                                            <option value={1} key={1}>
                                              Texto
                                            </option>{' '}
                                            {/* text */}
                                            <option value={2} key={2}>
                                              Texto em Área
                                            </option>{' '}
                                            {/* textarea */}
                                            <option value={3} key={3}>
                                              Email
                                            </option>{' '}
                                            {/* email */}
                                            <option value={4} key={4}>
                                              Data
                                            </option>{' '}
                                            {/* date */}
                                            <option value={5} key={5}>
                                              Senha
                                            </option>{' '}
                                            {/* password */}
                                            <option value={6} key={6}>
                                              Link
                                            </option>{' '}
                                            {/* url */}
                                            <option value={7} key={7}>
                                              Seleção de Cor
                                            </option>{' '}
                                            {/* color */}
                                            <option value={8} key={8}>
                                              Caixa de Seleção
                                            </option>{' '}
                                            {/* checkbox */}
                                            <option value={9} key={9}>
                                              Interruptor
                                            </option>{' '}
                                            {/* switch */}
                                            <option value={10} key={10}>
                                              Barra de Arrastar
                                            </option>{' '}
                                            {/* range */}
                                            <option value={11} key={11}>
                                              Opções
                                            </option>{' '}
                                            {/* radio */}
                                            <option value={12} key={12}>
                                              Lista Única Escolha
                                            </option>{' '}
                                            {/* select */}
                                            <option value={13} key={13}>
                                              Lista Múltipla Escolha
                                            </option>{' '}
                                            {/* select multiple */}
                                            <option value={14} key={14}>
                                              Número
                                            </option>{' '}
                                            {/* number */}
                                          </Form.Control>
                                        </Form.Group>

                                        {textOptions.includes(
                                          question.responseType,
                                        ) && (
                                          <Form.Group
                                            controlId={`formSection${secId}Question${queId}MinLength`}
                                          >
                                            <Form.Label>
                                              Mínimo de Caracteres
                                            </Form.Label>
                                            <Form.Control
                                              type="number"
                                              placeholder="Informe o mínimo de caracteres"
                                              onChange={e =>
                                                changeField(
                                                  e.target.value,
                                                  'minLength',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                              value={question.minLength}
                                            />
                                          </Form.Group>
                                        )}
                                        {textOptions.includes(
                                          question.responseType,
                                        ) && (
                                          <Form.Group
                                            controlId={`formSection${secId}Question${queId}MaxLength`}
                                          >
                                            <Form.Label>
                                              Máximo de Caracteres
                                            </Form.Label>
                                            <Form.Control
                                              type="number"
                                              placeholder="Informe o máximo de caracteres"
                                              onChange={e =>
                                                changeField(
                                                  e.target.value,
                                                  'maxLength',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                              value={question.maxLength}
                                            />
                                          </Form.Group>
                                        )}

                                        {/* Text type */}
                                        {question.responseType === 1 && (
                                          <Form.Group
                                            controlId={`formSection${secId}Question${queId}Mask`}
                                          >
                                            <Form.Label>
                                              Adicionar máscara
                                            </Form.Label>
                                            <Form.Control
                                              as="select"
                                              value={question.mask || -1}
                                              onChange={e =>
                                                changeField(
                                                  parseInt(e.target.value, 10),
                                                  'mask',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                            >
                                              <option
                                                disabled
                                                value={-1}
                                                key={-1}
                                              >
                                                Selecione o máscara desejada
                                              </option>
                                              {/* TODO: Make these a state array */}
                                              <option value={1} key={1}>
                                                Telefone
                                              </option>
                                              <option value={2} key={2}>
                                                Celular
                                              </option>
                                              <option value={3} key={3}>
                                                Dinheiro
                                              </option>
                                              <option value={4} key={4}>
                                                Mês/Ano
                                              </option>
                                              <option value={5} key={5}>
                                                Número do Cartão
                                              </option>
                                              <option value={6} key={6}>
                                                CEP
                                              </option>
                                              <option value={7} key={7}>
                                                CPF
                                              </option>
                                            </Form.Control>
                                          </Form.Group>
                                        )}

                                        <Accordion defaultActiveKey="0">
                                          {question.responseOptions.length >
                                            0 &&
                                            question.responseOptions.map(
                                              (responseOption, optId) => (
                                                <Card
                                                  key={`responseOption-${optId}`}
                                                >
                                                  <Button
                                                    id={`formSection${secId}Question${queId}ResponseOption${optId}DeleteButton`}
                                                    size="sm"
                                                    type="button"
                                                    variant="outline-danger"
                                                    className="close-button"
                                                    onClick={() =>
                                                      deleteGroup(
                                                        secId,
                                                        queId,
                                                        optId,
                                                      )
                                                    }
                                                  >
                                                    X
                                                  </Button>
                                                  <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey={`${optId}`}
                                                  >
                                                    <h6 className="text-truncate text-left">
                                                      {`Opção: ${responseOption.name}`}
                                                    </h6>
                                                  </Accordion.Toggle>
                                                  <Accordion.Collapse
                                                    eventKey={`${optId}`}
                                                  >
                                                    <Card.Body>
                                                      <Form.Group
                                                        controlId={`formSection${secId}Question${queId}ResponseOption${optId}Name`}
                                                      >
                                                        <Form.Label>
                                                          Nome da Resposta
                                                        </Form.Label>
                                                        <span className="ml-1 text-danger">
                                                          *
                                                        </span>
                                                        <Form.Control
                                                          type="text"
                                                          required
                                                          placeholder="Informe o texto da resposta"
                                                          onChange={e =>
                                                            changeField(
                                                              e.target.value,
                                                              'name',
                                                              secId,
                                                              queId,
                                                              optId,
                                                            )
                                                          }
                                                          value={
                                                            responseOption.name
                                                          }
                                                        />
                                                      </Form.Group>

                                                      <Form.Group
                                                        controlId={`formSection${secId}Question${queId}ResponseOption${optId}Explanation`}
                                                      >
                                                        <Form.Label>
                                                          Explicação da Resposta
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          placeholder="Informe a explicação da resposta"
                                                          onChange={e =>
                                                            changeField(
                                                              e.target.value,
                                                              'explanation',
                                                              secId,
                                                              queId,
                                                              optId,
                                                            )
                                                          }
                                                          value={
                                                            responseOption.explanation
                                                          }
                                                        />
                                                      </Form.Group>

                                                      <Form.Group
                                                        controlId={`formSection${secId}Question${queId}ResponseOption${optId}Weight`}
                                                      >
                                                        <Form.Label>
                                                          Peso da Resposta
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="number"
                                                          placeholder="Informe o peso da resposta"
                                                          onChange={e =>
                                                            changeField(
                                                              parseInt(
                                                                e.target.value,
                                                                10,
                                                              ),
                                                              'weight',
                                                              secId,
                                                              queId,
                                                              optId,
                                                            )
                                                          }
                                                          value={
                                                            responseOption.weight
                                                          }
                                                        />
                                                      </Form.Group>
                                                    </Card.Body>
                                                  </Accordion.Collapse>
                                                </Card>
                                              ),
                                            )}
                                        </Accordion>

                                        {multiOptions.includes(
                                          question.responseType,
                                        ) &&
                                          question.responseOptions &&
                                          question.responseOptions.length ===
                                            0 && (
                                            <div className="text-info">
                                              <strong>Dica: </strong>
                                              Alguns tipos de pergunta oferecem
                                              opções como resposta. Clique em
                                              &ldquo;Adicionar Opção&rdquo; para
                                              ver:
                                            </div>
                                          )}
                                        {multiOptions.includes(
                                          question.responseType,
                                        ) &&
                                          question.responseOptions &&
                                          question.responseOptions.length ===
                                            1 && (
                                            <div className="text-info">
                                              <strong>Dica: </strong>
                                              Deseja adicionar uma nova opção?
                                            </div>
                                          )}

                                        {multiOptions.includes(
                                          question.responseType,
                                        ) && (
                                          <Button
                                            id={`formSection${secId}Question${queId}`}
                                            type="button"
                                            variant="primary"
                                            className="my-2"
                                            onClick={() =>
                                              newGroup(secId, queId)
                                            }
                                          >
                                            Adicionar Opção
                                          </Button>
                                        )}

                                        {multiOptions.includes(
                                          question.responseType,
                                        ) && (
                                          <Form.Group
                                            controlId={`formSection${secId}Question${queId}AllowOther`}
                                          >
                                            <Form.Check
                                              type="checkbox"
                                              label={
                                                'Permitir a opção "Outro"?'
                                              }
                                              onChange={() =>
                                                changeField(
                                                  !question.allowOther,
                                                  'allowOther',
                                                  secId,
                                                  queId,
                                                )
                                              }
                                              checked={question.allowOther}
                                            />
                                          </Form.Group>
                                        )}
                                        {multiOptions.includes(
                                          question.responseType,
                                        ) &&
                                          question.allowOther && (
                                            <Form.Group
                                              controlId={`formSection${secId}Question${queId}OtherOptLabel`}
                                            >
                                              <Form.Label>
                                                Label da opção
                                                &ldquo;Outro&rdquo;
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                placeholder="Informe a label da opção"
                                                onChange={e =>
                                                  changeField(
                                                    e.target.value,
                                                    'otherOption',
                                                    secId,
                                                    queId,
                                                  )
                                                }
                                                value={question.otherOption}
                                              />
                                            </Form.Group>
                                          )}
                                        {multiOptions.includes(
                                          question.responseType,
                                        ) &&
                                          question.allowOther && (
                                            <Form.Group
                                              controlId={`formSection${secId}Question${queId}OtherLabel`}
                                            >
                                              <Form.Label>
                                                Label do campo
                                                &ldquo;Outro&rdquo;
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                placeholder="Informe a label do campo"
                                                onChange={e =>
                                                  changeField(
                                                    e.target.value,
                                                    'otherLabel',
                                                    secId,
                                                    queId,
                                                  )
                                                }
                                                value={question.otherLabel}
                                              />
                                            </Form.Group>
                                          )}
                                        {multiOptions.includes(
                                          question.responseType,
                                        ) &&
                                          question.allowOther && (
                                            <Form.Group
                                              controlId={`formSection${secId}Question${queId}OtherPlaceholder`}
                                            >
                                              <Form.Label>
                                                Placeholder do campo
                                                &ldquo;Outro&rdquo;
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                placeholder="Informe o placeholder do campo"
                                                onChange={e =>
                                                  changeField(
                                                    e.target.value,
                                                    'otherPlaceholder',
                                                    secId,
                                                    queId,
                                                  )
                                                }
                                                value={
                                                  question.otherPlaceholder
                                                }
                                              />
                                            </Form.Group>
                                          )}
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                ))}
                            </Accordion>

                            {section.questions &&
                              section.questions.length === 0 && (
                                <div className="text-info">
                                  <strong>Dica: </strong>
                                  Uma sessão pode ter várias perguntas. Clique
                                  em &ldquo;Adicionar Pergunta&rdquo; para ver:
                                </div>
                              )}
                            {section.questions &&
                              section.questions.length === 1 && (
                                <div className="text-info">
                                  <strong>Dica: </strong>
                                  Deseja adicionar uma nova pergunta?
                                </div>
                              )}

                            <Button
                              id={`formSection${secId}AddQuestion`}
                              type="button"
                              variant="primary"
                              className="mt-2"
                              onClick={() => newGroup(secId)}
                            >
                              Adicionar Pergunta
                            </Button>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    ))}
                </Accordion>

                {constructorUnity.sections &&
                  constructorUnity.sections.length === 0 && (
                    <div className="text-info">
                      <strong>Dica: </strong>
                      As perguntas são divididas em sessões. Se você não quiser
                      utilizar essa divisão, basta &ldquo;Adicionar
                      Sessão&rdquo; e não preencher o nome e a descrição.
                    </div>
                  )}
                {constructorUnity.sections &&
                  constructorUnity.sections.length === 1 && (
                    <div className="text-info">
                      <strong>Dica: </strong>
                      Deseja adicionar uma nova sessão?
                    </div>
                  )}

                <Button
                  id="formAddSection"
                  type="button"
                  variant="primary"
                  className="mt-2"
                  onClick={() => newGroup()}
                >
                  Adicionar Sessão
                </Button>

                <br />

                <Form.Group controlId="formThanksMessage" className="mt-2">
                  <Form.Label>Mensagem de Agradecimento</Form.Label>

                  {/* TODO: Make this a new mention component */}
                  {getPreviousQuestionLabels('thanksMessage').length > 0 && (
                    <Form.Control
                      as="select"
                      size="sm"
                      value={-1}
                      className="ml-2 d-inline-block w-auto"
                      onChange={e =>
                        mentionLabel(e.target.value, 'thanksMessage')
                      }
                    >
                      <option disabled value={-1} key={-1}>
                        Mencione uma resposta
                      </option>
                      {getPreviousQuestionLabels('thanksMessage').map(
                        (label, index) => {
                          return (
                            <option value={label} key={index}>
                              {label}
                            </option>
                          );
                        },
                      )}
                    </Form.Control>
                  )}

                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Digite a mensagem de agradecimento, a última tela que o cliente vai visualizar."
                    onChange={e => changeField(e.target.value, 'thanksMessage')}
                    value={constructorUnity.thanksMessage}
                  />
                </Form.Group>

                <Link to="/construtor">
                  <Button
                    id="formGoBack"
                    variant="secondary"
                    type="button"
                    className="mt-2"
                  >
                    Voltar
                  </Button>
                </Link>

                <Button
                  id="formSave"
                  variant="success"
                  type="submit"
                  className="mt-2 ml-2"
                >
                  Salvar Pesquisa
                </Button>
              </Form>
            </Container>
          )}
          {!constructorId && (
            <EmptyResults
              message="Falha ao recuperar as informações desta pesquisa."
              redirectLink="/construtor"
              redirectAction="Voltar"
            />
          )}
          {/* <h2 className="ml-5 mb-3">Respostas</h2>
          {researches.length > 0 &&
            <Researches
              researcheResults={researches}
              page={page}
              totalPages={totalPages}
              changePage={handleChangePage}
              previousRoute={`/construtor/${constructorId}`}
            />
          }
          {researches.length === 0 &&
            <Container>
              <EmptyResults
                message={`Esta pesquisa ainda não possui respostas.`}
                redirectLink="/construtor"
                redirectAction="Voltar"
              />
            </Container>
          } */}
        </Route>
      </Switch>
    </div>
  );
};

export default ConstructorUnity;
