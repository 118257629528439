import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { Table, Button, Form } from 'react-bootstrap';

import Client from './Client';
import Researches from '../researches/Researches';
import Research from '../researches/Research';
import Paging from '../../components/paging/Paging';
import { get } from '../../app/axios';
import { selectClients, setClients, clearClients } from './clientsSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import {
  selectDateLocale,
  selectDateOptions,
} from '../../components/date/dateSlice';
import errorHandler from '../../app/error';
import { selectProducts } from '../products/productsSlice';

const Clients = props => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit] = useState(20);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [filter, setFilter] = useState('');
  const [product, setProduct] = useState('');
  const [productFilter, setProductFilter] = useState('');
  const products = useSelector(selectProducts);

  const clients = useSelector(selectClients);
  const locale = useSelector(selectDateLocale);
  const options = useSelector(selectDateOptions);

  const { location } = props;
  const { pathname } = location;
  const previousRoute = pathname.replace(`${pathname}`, '/');
  const history = useHistory();

  const getClients = useCallback(async () => {
    dispatch(clearClients());
    dispatch(increaseLoadingState());

    try {
      const response = await get(
        `/clients?limit=${limit}&page=${page}${filter}${productFilter}`,
      );
      if (response.message) {
        response.context = 'recuperar clientes';
        errorHandler(response);
      } else if (response.clients.length > 0) {
        dispatch(setClients(response.clients));
        setTotalPage(response.totalPages);
      }
    } catch (error) {
      error.context = 'recuperar clientes';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
  }, [page, limit, filter, productFilter, dispatch]);

  useEffect(() => {
    getClients().then();
  }, [getClients]);

  const handleChangePage = pageNum => {
    setPage(pageNum);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (email) {
      setFilter(`&email=${email}`);
    } else if (name) {
      setFilter(`&name=${name}`);
    } else {
      setFilter('');
    }
    if (product) {
      setProductFilter(`&type=${product}`);
    } else {
      setProductFilter('');
    }
    setPage(1);
  };

  const handleClearFilter = () => {
    setEmail('');
    setName('');
    setFilter('');
    setProduct('');
    setProductFilter('');
  };

  return (
    <div>
      <Switch>
        <Route path="/clientes/:clientId" component={Client} />
        <Route path="/clientes/:clientId/pesquisas" component={Researches} />
        <Route
          path="/clientes/:clientId/pesquisas/:researchId"
          component={Research}
        />
        <Route path={match.path}>
          <div className="m-5">
            <div className="mt-5 d-flex justify-content-between">
              <h2 className="mb-3">Clientes</h2>
              <Button
                className="mr-2"
                style={{ height: '38px' }}
                variant="primary"
                onClick={() => getClients()}
              >
                ⟳
              </Button>
            </div>
            <div className="my-3">
              <h4>Filtro</h4>
              <p>Filtre por email ou pelo nome do cliente.</p>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Filtrar por email"
                    value={email}
                    disabled={!!name}
                    autoFocus
                    onChange={e => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="name">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Filtrar por nome"
                    value={name}
                    disabled={!!email}
                    onChange={e => setName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="product">
                  <Form.Label>Produto</Form.Label>
                  <Form.Control
                    as="select"
                    value={product || -1}
                    onChange={e => setProduct(e.target.value)}
                  >
                    <option disabled value={-1} key={-1}>
                      Selecione um produto
                    </option>
                    {products.map((productItem, index) => {
                      return (
                        <option value={productItem._id} key={index}>
                          {productItem.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Button
                  className="mr-1"
                  type="submit"
                  variant="primary"
                  size="sm"
                >
                  Filtrar
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={handleClearFilter}
                >
                  Limpar Filtro
                </Button>
              </Form>
            </div>
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Celular</th>
                  <th>Email</th>
                  <th>Data</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {clients &&
                  clients.map((client, i) => (
                    <tr
                      key={`client-${i}`}
                      onClick={() =>
                        history.push(`/clientes/${client._id}/pesquisas`)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <td>{client.name}</td>
                      <td>{client.phone}</td>
                      <td>
                        <a href={`mailto:${client.email}`}>{client.email}</a>
                      </td>
                      <td>
                        {new Date(client.created_at).toLocaleDateString(
                          locale,
                          options,
                        )}
                      </td>
                      <td>
                        <Link to={`/clientes/${client._id}/pesquisas`}>
                          <Button variant="primary" size="sm">
                            Selecionar
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {totalPages > 1 && (
              <Paging
                page={page}
                totalPages={totalPages}
                changePage={handleChangePage}
              />
            )}
            <Link to={`${previousRoute}`}>
              <Button variant="secondary">Voltar</Button>
            </Link>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default Clients;
