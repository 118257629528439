import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';

import EmptyResults from '../empty/EmptyResults';

const TextCard = ({
  quant,
  title,
  routeTo,
  singleText,
  pluralText,
  emptyText,
  disabledButton,
  buttonText,
}) => {
  return (
    <Card className="m-2">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        {quant > 0 && (
          <Card.Text className="px-2 py-3 mb-4 text-center">
            {`${quant} ${quant > 1 ? pluralText : singleText}`}
          </Card.Text>
        )}
        {quant === 0 && <EmptyResults message={emptyText} />}
        <div className="text-right">
          <Link to={`${routeTo}`}>
            <Button variant="primary" disabled={disabledButton}>
              {buttonText}
            </Button>
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TextCard;
