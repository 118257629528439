import axios from 'axios';
import _cloneDeep from 'lodash-es/cloneDeep';

// Dev
// const URL = 'http://localhost:9000/api/v1';

const URL = process.env.REACT_APP_URL;

axios.interceptors.request.use(config => {
  if (typeof window === 'undefined') {
    return config;
  }
  const token = window.localStorage.getItem('token');
  if (token) {
    const configClone = _cloneDeep(config);
    configClone.headers.Authorization = `Bearer ${token}`;
    return configClone;
  }
  return config;
});

export const get = endPoint => {
  return axios.get(URL + endPoint).then(
    response => response.data,
    error => error,
  );
};

export const post = (endPoint, JSONdata) => {
  return axios.post(URL + endPoint, JSONdata).then(
    response => response.data,
    error => error,
  );
};

export const put = (endPoint, JSONdata) => {
  return axios.put(URL + endPoint, JSONdata).then(
    response => response.data,
    error => error,
  );
};

export const del = (endPoint, JSONdata) => {
  return axios.delete(URL + endPoint, JSONdata).then(
    response => response.data,
    error => error,
  );
};
