import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

const ResearchScore = ({ score }) => {
  const [variant, setVariant] = useState('');
  const [title, setTitle] = useState('');
  const [range, setRange] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    if (score < 26) {
      setVariant('danger');
      setTitle('Atenção, RISCO MUITO ALTO!');
      setRange('Pontuação entre 0% e 25%');
      setText(`
        Sua empresa não apresenta aderência as melhores práticas de privacidade da dados pessoais, o que aumenta as chances de um incidente ou vazamento de dados.
        As multas para estes casos podem chegar em R$50.000.000,00 ou 2% do faturamento global da empresa.
        Sugerimos que a sua empresa inicie o quanto antes as atividades básicas de proteção de dados, reduzindo riscos ao seu negócio.
      `);
    } else if (score < 51) {
      setVariant('danger');
      setTitle('Atenção, RISCO ALTO!');
      setRange('Pontuação entre 26% e 50%');
      setText(`
        Sua empresa tem baixa aderência as melhores práticas de privacidade da dados pessoais, o que aumenta as chances de um incidente ou vazamento de dados.
        As multas para estes casos podem chegar em R$50.000.000,00 ou 2% do faturamento global da empresa.
        Sugerimos que a sua empresa inicie o quanto antes as atividades básicas de proteção de dados, reduzindo riscos ao seu negócio.
      `);
    } else if (score < 76) {
      setVariant('warning');
      setTitle('Atenção, RISCO MÉDIO!');
      setRange('Pontuação entre 51% e 75%');
      setText(`
        Sua empresa está no caminho certo para a LGPD, entretanto é necessário que esteja em aderência as melhores práticas de privacidade da dados pessoais, o que diminuiria as chances de um incidente ou vazamento de dados.
        As multas para estes casos podem chegar em R$50.000.000,00 ou 2% do faturamento global da empresa.
        Sugerimos que a sua empresa realize uma revisão junto a um DPO para garantir que suas ações estejam aderentes a lei.
      `);
    } else if (score < 91) {
      setVariant('success');
      setTitle('Atenção, RISCO BAIXO!');
      setRange('Pontuação entre 76% e 90%');
      setText(`
        Sua empresa está no caminho certo para a LGPD, entretanto é necessário que esteja em aderência as melhores práticas de privacidade da dados pessoais, o que diminuiria as chances de um incidente ou vazamento de dados.
        As multas para estes casos podem chegar em R$50.000.000,00 ou 2% do faturamento global da empresa.
        Sugerimos que a sua empresa realize uma revisão junto a um DPO para garantir que suas ações estejam aderentes a lei
      `);
    } else {
      setVariant('success');
      setTitle('Atenção, RISCO MUITO BAIXO!');
      setRange('Pontuação acima de 91%');
      setText(`
        Sua empresa está muito aderente a a LGPD, entretanto para garantir que vocês estão realmente em conformidade é recomendavel uma revisão por um DPO, o que diminuiria as chances de um incidente ou vazamento de dados.
      `);
    }
  }, [score]);

  return (
    <Alert variant={variant}>
      <Alert.Heading>{title}</Alert.Heading>
      <p>{range}</p>
      <hr />
      <p className="mb-0">{text}</p>
    </Alert>
  );
};

export default ResearchScore;
