/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { Accordion, Container, Button, Form, Card } from 'react-bootstrap';
import _cloneDeep from 'lodash-es/cloneDeep';
import _isEmpty from 'lodash-es/isEmpty';

import {
  selectQuest,
  setQuest,
  clearQuest,
  selectAnswer,
  setAnswer,
  clearAnswer,
} from './answerSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import { get, put, post } from '../../app/axios';
import errorHandler from '../../app/error';
// import EmptyResults from '../../components/empty/EmptyResults';
import { displayNotification } from '../../components/notifications/notificationSlice';
import Checkbox from '../../components/checkbox/Checkbox';
import Radio from '../../components/checkbox/Radio';

import talentStyles from '../../sass/answer/talentflow.module.scss';
import privacyStyles from '../../sass/answer/privacyflow.module.scss';

const Answer = props => {
  const { location } = props;
  const { pathname } = location;
  const pathnames = pathname.split('/').filter(x => x);

  const dispatch = useDispatch();

  const [emailRegex] = useState(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
  const [colorRegex] = useState(/^#(?:[0-9a-fA-F]{3}){1,2}$/);
  const [urlRegex] = useState(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  );
  const [fullDateRegex] = useState(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
  );
  const [teleRegex] = useState(/^\([1-9]{2}\)(?:[2-8])[0-9]{3}\-[0-9]{4}$/);
  const [cellRegex] = useState(
    /^\([1-9]{2}\)(?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/,
  );
  const [moneyRegex] = useState(/^[1-9]\d{0,2}(\.\d{3})*,\d{2}$/);
  const [MMYYYYRegex] = useState(/^(0[1-9]|1[012])\/(19|20)\d{2}$/);
  const [credtiCardRegex] = useState({
    Visa: /^4[0-9]{12}(?:[0-9]{3})$/,
    Mastercard: /^5[1-5][0-9]{14}$/,
    Amex: /^3[47][0-9]{13}$/,
    DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
  });
  // const [CepRegex] = useState(/^([\d]{2})\.?([\d]{3})\-?([\d]{3})$/);
  const [CpfRegex] = useState(
    /^([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})$/,
  );

  const [multiOptions] = useState([11, 12, 13]);
  const [currentPage, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [styles, setStyle] = useState({});
  const [client, setClient] = useState({});
  const [isNew, setIsNew] = useState(false);
  const [emptyClientId, setEmptyClientId] = useState(true);
  const { productId, questId, researchId } = useParams();

  const quest = useSelector(selectQuest);
  const answer = useSelector(selectAnswer);

  useEffect(() => {
    if (isInvalid(researchId)) {
      setIsNew(true);
    }
  }, [researchId]);

  const getAnswer = useCallback(async () => {
    if (!questId) {
      return;
    }
    dispatch(clearQuest());
    dispatch(clearAnswer());
    dispatch(increaseLoadingState());

    try {
      const response = await get(`/quests/${questId}`);
      if (response.message) {
        response.context = 'buscar pesquisa';
        errorHandler(response);
      } else if (response) {
        const responseMapped = mapPages(response);
        dispatch(setQuest(responseMapped));

        if (researchId) {
          getResearch();
        } else {
          mapToResearch(responseMapped);
        }
      } else if (response.message) {
        response.context = 'buscar pesquisa';
        errorHandler(response);
      }
    } catch (error) {
      error.context = 'buscar pesquisa';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
    // Dont add "getResearch" and "mapToResearch" here as a dependency
    // We cannot access them before initialization
  }, [dispatch, questId, researchId]);

  useEffect(() => {
    if (pathnames.includes('talentflow')) {
      setStyle(talentStyles);
    } else if (pathnames.includes('privacyflow')) {
      setStyle(privacyStyles);
    } else {
      // TODO:
      // Add a default style??
      setStyle(talentStyles);
    }
    getAnswer().then();
    // Dont add "pathnames" here as a dependency
    // We would get an infinite loop otherwise
  }, [getAnswer]);

  const mapPages = resp => {
    let page = 0;

    // First page: introduction message
    if (resp.introMessage) {
      page += 1;
      resp.introPage = 1;
    }

    // Configuration for every section and question on the same page
    if (resp.sectionOrientation === 1) {
      page += 1;
      resp.sections.forEach(section => {
        section.pages = [page];
        section.questions.forEach(question => {
          question.page = page;
        });
      });
    }

    // Dynamic configuration
    if (resp.sectionOrientation === 2) {
      resp.sections.forEach(section => {
        page += 1;
        section.pages = [page];

        // One page per section
        if (section.questionOrientation === 1) {
          section.questions.forEach(question => {
            question.page = page;
          });
        }

        // One page per question
        if (section.questionOrientation === 2) {
          section.questions.forEach((question, queId) => {
            if (queId === 0) {
              question.page = page;
            } else {
              page += 1;
              question.page = page;
              section.pages.push(page);
            }
          });
        }
      });
    }

    // Last page: thanks message
    if (resp.thanksMessage) {
      page += 1;
      resp.thanksPage = page;
    }

    setTotalPage(page);
    return resp;
  };

  const mapToResearch = useCallback(
    resp => {
      const mapAnswer = {};

      mapAnswer._id = resp._id;
      mapAnswer.type = resp.type;
      mapAnswer.sections = [];

      resp.sections.forEach((section, index) => {
        mapAnswer.sections.push({
          name: section.name,
          description: section.description,
          questions: [],
        });

        section.questions.forEach(question => {
          let answerType = '';
          if (multiOptions.includes(question.responseType)) {
            if (question.responseType === 13) {
              answerType = [];
            } else {
              answerType = -1;
            }
          }

          mapAnswer.sections[index].questions.push({
            explanation: question.explanation,
            question: question.question,
            answer: answerType,
            other: '',
            justification: '',
          });
        });
      });

      dispatch(setAnswer(mapAnswer));
    },
    [dispatch, multiOptions],
  );

  const getResearch = useCallback(async () => {
    if (!researchId) {
      return;
    }
    dispatch(increaseLoadingState());

    try {
      const response = await get(`/researches/${researchId}`);
      if (response.message) {
        response.context = 'buscar respostas';
        errorHandler(response);
      } else if (response) {
        dispatch(setAnswer(response));
        setClient(response.clientId);
        setEmptyClientId(false);
      } else if (response.message) {
        response.context = 'buscar respostas';
        errorHandler(response);
      }
    } catch (error) {
      error.context = 'buscar respostas';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
  }, [dispatch, researchId]);

  const changeField = (value, target, responseTarget, secId, queId, optId) => {
    const answerClone = _cloneDeep(answer);

    if (optId || optId === 0) {
      answerClone.sections[secId].questions[queId].responseOptions[optId][
        target
      ] = value;
    } else if (queId || queId === 0) {
      // TODO:
      // Check mask validations
      // question.mask
      // 1-Telefone
      // 2-Celular
      // 3-Dinheiro
      // 4-Mês/Ano
      // 5-Número do Cartão
      // 6-CEP
      // 7-CPF

      if (Array.isArray(answerClone.sections[secId].questions[queId][target])) {
        const itemFoundIndex = answerClone.sections[secId].questions[queId][
          target
        ].findIndex(item => item === value);
        if (itemFoundIndex === -1) {
          answerClone.sections[secId].questions[queId][target].push(value);
        } else {
          answerClone.sections[secId].questions[queId][target].splice(
            itemFoundIndex,
            1,
          );
        }
      } else {
        answerClone.sections[secId].questions[queId][target] = value;
      }
    } else if (secId || secId === 0) {
      answerClone.sections[secId][target] = value;
    } else {
      answerClone[target] = value;
    }

    if (responseTarget) {
      const clientClone = _cloneDeep(client);
      clientClone[responseTarget] = value;
      setClient(clientClone);
    }

    dispatch(setAnswer(answerClone));
  };

  const isInvalid = value => {
    if (value === '' || value === undefined) {
      return true;
    }
    if (value === -1) {
      return true;
    }
    if (_isEmpty(value)) {
      return true;
    }
    return false;
  };

  const handleNext = () => {
    let emptyError = true;
    let notEmpty = true;
    let errorMsg = '';
    let startMsg = '';

    quest.sections.forEach((section, secId) => {
      section.questions.forEach((question, queId) => {
        // Checks what questions should be validated
        if (question.page === currentPage) {
          const resp = _cloneDeep(answer.sections[secId].questions[queId]);

          if (question.responseLabel) {
            startMsg = `O campo "${question.responseLabel}"`;
          } else {
            startMsg = `Este campo`;
          }

          // Checks if the answer is empty
          if (isInvalid(resp.answer)) {
            notEmpty = false;

            // Checks if the question is required
            if (question.required) {
              errorMsg = `${startMsg} deve ser preenchido.`;
            }
          }

          // Checks chars fields: text textarea email password url
          if ([1, 2, 3, 5, 6].includes(question.responseType)) {
            if (
              notEmpty &&
              question.minLength &&
              resp.answer.length < question.minLength
            ) {
              errorMsg = `${startMsg} deve ter no mínimo ${question.minLength} caracteres.`;
            }
            if (
              notEmpty &&
              question.maxLength &&
              resp.answer.length < question.maxLength
            ) {
              errorMsg = `${startMsg} deve ter no máximo ${question.maxLength} caracteres.`;
            }
          }
          if (question.responseType === 1) {
            switch (question.mask) {
              case '1': // Checks valid telephne
                if (notEmpty && !teleRegex.test(resp.answer)) {
                  errorMsg = `${startMsg} deve ser um telefone válido.`;
                }
                break;
              case '2': // Checks valid cellphne
                if (notEmpty && !cellRegex.test(resp.answer)) {
                  errorMsg = `${startMsg} deve ser um celular válido.`;
                }
                break;
              case '3': // Checks valid money
                if (notEmpty && !moneyRegex.test(resp.answer)) {
                  errorMsg = `${startMsg} deve ser uma quantia válida.`;
                }
                break;
              case '4': // Checks valid month/year
                if (notEmpty && !MMYYYYRegex.test(resp.answer)) {
                  errorMsg = `${startMsg} deve ser uma data válida.`;
                }
                break;
              case '5': // Checks valid credit card
                // eslint-disable-next-line no-case-declarations
                let validCard = false;
                // eslint-disable-next-line no-restricted-syntax
                for (const cartao in credtiCardRegex) {
                  if (cartao.test(resp.answer)) {
                    validCard = true;
                    break;
                  }
                }
                if (notEmpty && validCard) {
                  errorMsg = `${startMsg} deve ser um cartão válido.`;
                }
                break;
              case '7': // Checks valid CPF
                if (notEmpty && !CpfRegex.test(resp.answer)) {
                  errorMsg = `${startMsg} deve ser um CPF válida.`;
                }
                break;
              default:
                errorMsg = '';
            }
          }
          // Checks valid email
          if (question.responseType === 3) {
            if (
              notEmpty &&
              !emailRegex.test(String(resp.answer).toLowerCase())
            ) {
              errorMsg = `${startMsg} deve ser um email válido.`;
            }
          }
          // Checks valid date
          if (question.responseType === 4) {
            if (notEmpty && !fullDateRegex.test(resp.answer)) {
              errorMsg = `${startMsg} deve ser uma data válida.`;
            }
          }
          // Checks valid url
          if (question.responseType === 6) {
            if (notEmpty && !urlRegex.test(resp.answer)) {
              errorMsg = `${startMsg} deve ser uma url válida.`;
            }
          }
          // Checks valid color
          if (question.responseType === 7) {
            if (notEmpty && !colorRegex.test(resp.answer)) {
              errorMsg = `${startMsg} deve ser uma cor válida.`;
            }
          }

          // Checks if the other field is valid
          if (
            question.required &&
            question.allowOther &&
            resp.answer &&
            isInvalid(resp.other)
          ) {
            if (question.otherLabel) {
              startMsg = `O campo "${question.otherLabel}"`;
            } else {
              startMsg = `Este campo`;
            }
            errorMsg = `${startMsg} deve ser prenchido.`;
          }

          // TODO:
          // Validate the other fields
          // Justification
          // Attachment

          if (errorMsg) {
            dispatch(
              displayNotification({
                title: 'Atenção!',
                text: errorMsg,
                variant: 'danger',
              }),
            );
            emptyError = false;
            errorMsg = '';
          }
        }
      });
    });

    if (emptyError) {
      setPage(currentPage + 1);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const answerClone = _cloneDeep(answer);
    answerClone.type = productId;
    answerClone.status = true;
    answerClone.sections.map(section => {
      return section.questions.map(question => {
        // Turn array into string
        if (Array.isArray(question.answer)) {
          question.answer = JSON.stringify(question.answer);
        }
        return question;
      });
    });

    let resp = {};
    try {
      dispatch(increaseLoadingState());

      // Save the client / lead
      if (emptyClientId) {
        const clientClone = _cloneDeep(client);
        clientClone.type = [productId];
        const clientResp = await post('/clients', clientClone);

        // TODO:
        // Checks if the client already exists
        // I don't care about errors in this request
        // because we may probably get other error on the research request
        // if (clientResp.message) {
        //   clientResp.context = 'salvar seus dados';
        //   errorHandler(clientResp);
        // }

        answerClone.clientId = clientResp._id;
      } else {
        answerClone.clientId = client._id;
      }

      // Create or update the research
      if (isNew) {
        resp = await post('/researches', answerClone);

        // TODO:
        // Add the resp _id to the query string
      } else {
        resp = await put(`/researches/${researchId}`, answerClone);
      }

      // Handle response
      if (resp.message) {
        resp.context = 'salvar o questionário';
        errorHandler(resp);
      } else {
        dispatch(
          displayNotification({
            title: 'Sucesso!',
            text: 'Questionário salvo.',
            variant: 'success',
          }),
        );
      }

      dispatch(decreaseLoadingState());
    } catch (error) {
      error.context = 'salvar o questionário';
      errorHandler(error);
      dispatch(decreaseLoadingState());
    }
  };

  const mouseMove = event => {
    const mouseY = event.clientY;
    const mouseX = event.clientX;

    const background = document.querySelector(`.${styles.bg}`);
    background.style.transform = `translate(${-mouseX * 0.02}px, ${
      -mouseY * 0.02
    }px)`;
  };

  const handleTextMention = string => {
    const fields = string.match(/@(.*?)@/g);
    if (!fields) {
      return string;
    }
    let text = _cloneDeep(string);
    let resp = '';

    fields.forEach(field => {
      quest.sections.forEach((section, secId) => {
        section.questions.forEach((question, queId) => {
          if (field === `@${question.responseLabel}@`) {
            resp = answer.sections[secId].questions[queId].answer;
          }
        });
      });
      text = text.replace(field, resp);
    });

    return text;
  };

  return (
    <div className={styles.answer} onMouseMove={e => mouseMove(e)}>
      <div className={styles.bg} />
      {quest && (
        <Container>
          <Form onSubmit={handleSubmit}>
            <h1>{quest.name}</h1>

            {
              /* First page: introduction message */
              quest.introMessage && currentPage === quest.introPage && (
                <p>{quest.introMessage}</p>
              )
            }

            {
              /* Last page: thanks message */
              quest.thanksMessage && currentPage === quest.thanksPage && (
                <p>{handleTextMention(quest.thanksMessage)}</p>
              )
            }

            {
              /* Sections */
              quest.sections &&
                quest.sections.length > 0 &&
                quest.sections.map((section, secId) => (
                  <div key={`section-${secId}`}>
                    {section.pages.includes(currentPage) && (
                      <>
                        {section.name && (
                          <h2>{handleTextMention(section.name)}</h2>
                        )}
                        {section.description && (
                          <p>
                            <small>
                              {handleTextMention(section.description)}
                            </small>
                          </p>
                        )}

                        {
                          /* Questions */
                          section.questions &&
                            section.questions.length > 0 &&
                            section.questions.map((question, queId) => (
                              <div key={`question-${queId}`}>
                                {currentPage === question.page && (
                                  <>
                                    <h4 className={styles.question}>
                                      {handleTextMention(question.question)}
                                      {question.required && (
                                        <span className="ml-1 text-danger">
                                          <small>
                                            {question.requiredLabel}
                                          </small>
                                        </span>
                                      )}
                                    </h4>
                                    {question.explanation && (
                                      <p>
                                        <small>
                                          {handleTextMention(
                                            question.explanation,
                                          )}
                                        </small>
                                      </p>
                                    )}

                                    {question.responseType === 1 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Label>
                                          {question.responseLabel}
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          required={question.required}
                                          placeholder={
                                            question.responsePlaceholder
                                          }
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.responseType === 2 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Label>
                                          {question.responseLabel}
                                        </Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          required={question.required}
                                          placeholder={
                                            question.responsePlaceholder
                                          }
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.responseType === 3 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Label>
                                          {question.responseLabel}
                                        </Form.Label>
                                        <Form.Control
                                          type="email"
                                          required={question.required}
                                          placeholder={
                                            question.responsePlaceholder
                                          }
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.responseType === 4 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Label>
                                          {question.responseLabel}
                                        </Form.Label>
                                        <Form.Control
                                          type="date"
                                          required={question.required}
                                          placeholder={
                                            question.responsePlaceholder
                                          }
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.responseType === 5 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Label>
                                          {question.responseLabel}
                                        </Form.Label>
                                        <Form.Control
                                          type="password"
                                          required={question.required}
                                          placeholder={
                                            question.responsePlaceholder
                                          }
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.responseType === 6 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Label>
                                          {question.responseLabel}
                                        </Form.Label>
                                        <Form.Control
                                          type="url"
                                          required={question.required}
                                          placeholder={
                                            question.responsePlaceholder
                                          }
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.responseType === 7 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <input
                                          type="color"
                                          id={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                          title={question.responseLabel}
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.responseType === 8 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Check
                                          className={styles.customCheckBox}
                                          type="checkbox"
                                          label={question.responseLabel}
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.responseType === 9 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Check
                                          type="switch"
                                          label={question.responseLabel}
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.responseType === 10 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Label>
                                          {question.responseLabel}
                                        </Form.Label>
                                        <Form.Control
                                          type="range"
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.responseType === 11 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Label>
                                          {question.responseLabel}
                                        </Form.Label>

                                        {
                                          /* Options */
                                          question.responseOptions &&
                                            question.responseOptions.length >
                                              0 &&
                                            question.responseOptions.map(
                                              (option, optId) => (
                                                <Radio
                                                  key={`option-${optId}`}
                                                  id={`answerSection${secId}Question${queId}ResponseType${question.responseType}Options${optId}`}
                                                  name={`answerSection${secId}Question${queId}ResponseType${question.responseType}Options${optId}`}
                                                  style={styles}
                                                  value={option.name}
                                                  label={option.name}
                                                  checked={
                                                    answer.sections[secId]
                                                      .questions[queId]
                                                      .answer === option.name
                                                  }
                                                  onChange={e =>
                                                    changeField(
                                                      e.target.value,
                                                      'answer',
                                                      question.responseTarget,
                                                      secId,
                                                      queId,
                                                    )
                                                  }
                                                />
                                                // <Form.Check
                                                //   className={styles.customCheckBox}
                                                //   key={'option-' + optId}
                                                //   name={`answerSection${secId}Question${queId}ResponseType${question.responseType}Options${optId}`}
                                                //   id={`answerSection${secId}Question${queId}ResponseType${question.responseType}Options${optId}`}
                                                //   type="radio"
                                                //   label={option.name}
                                                //   value={option.name}
                                                //   checked={answer.sections[secId].questions[queId].answer === option.name}
                                                //   onChange={(e) => changeField(e.target.value, 'answer', secId, queId)}
                                                // />
                                              ),
                                            )
                                        }

                                        {question.allowOther && (
                                          <Form.Check
                                            className={styles.customCheckBox}
                                            name={`answerSection${secId}Question${queId}ResponseType${
                                              question.responseType
                                            }Options${'other'}`}
                                            id={`answerSection${secId}Question${queId}ResponseType${
                                              question.responseType
                                            }Options${'other'}`}
                                            type="radio"
                                            label={question.otherOption}
                                            value="other"
                                            checked={
                                              answer.sections[secId].questions[
                                                queId
                                              ].answer === 'other'
                                            }
                                            onChange={e =>
                                              changeField(
                                                e.target.value,
                                                'answer',
                                                question.responseTarget,
                                                secId,
                                                queId,
                                              )
                                            }
                                          />
                                        )}
                                      </Form.Group>
                                    )}
                                    {question.responseType === 12 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Form.Label>
                                          {question.responseLabel}
                                        </Form.Label>
                                        <Form.Control
                                          as="select"
                                          required={question.required}
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'answer',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].answer || -1
                                          }
                                        >
                                          <option disabled value={-1} key={-1}>
                                            {question.responsePlaceholder}
                                          </option>

                                          {
                                            /* Options */
                                            question.responseOptions &&
                                              question.responseOptions.length >
                                                0 &&
                                              question.responseOptions.map(
                                                (option, optId) => {
                                                  return (
                                                    <option
                                                      value={option.weight}
                                                      key={`option-${optId}`}
                                                    >
                                                      {option.name}
                                                    </option>
                                                  );
                                                },
                                              )
                                          }
                                          {question.allowOther && (
                                            <option value="other">
                                              {question.otherOption}
                                            </option>
                                          )}
                                        </Form.Control>
                                      </Form.Group>
                                    )}
                                    {question.responseType === 13 && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}ResponseType${question.responseType}`}
                                      >
                                        <Accordion defaultActiveKey="0">
                                          <Card>
                                            <Card.Header>
                                              <Accordion.Toggle
                                                as={Button}
                                                variant="link"
                                                eventKey="0"
                                              >
                                                {question.responsePlaceholder}
                                              </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                              <Card.Body
                                                className={
                                                  styles.customCheckBox
                                                }
                                              >
                                                {/* {question.responseOptions && question.responseOptions.length > 0 && question.responseOptions.map((option, optId) => (
                                          <div className="d-inline-flex"><Badge pill variant="primary">{option.name}</Badge></div>
                                        ))} */}
                                                {question.responseOptions &&
                                                  question.responseOptions
                                                    .length > 0 &&
                                                  question.responseOptions.map(
                                                    (option, optId) => (
                                                      <Checkbox
                                                        key={`option-${optId}`}
                                                        id={`answerSection${secId}Question${queId}ResponseType${question.responseType}Options${optId}`}
                                                        style={styles}
                                                        value={option.name}
                                                        label={option.name}
                                                        checked={answer.sections[
                                                          secId
                                                        ].questions[
                                                          queId
                                                        ].answer.includes(
                                                          option.name,
                                                        )}
                                                        onChange={e =>
                                                          changeField(
                                                            e.target.value,
                                                            'answer',
                                                            question.responseTarget,
                                                            secId,
                                                            queId,
                                                          )
                                                        }
                                                      />
                                                      // <Form.Check
                                                      //   key={'option-' + optId}
                                                      //   id={`answerSection${secId}Question${queId}ResponseType${question.responseType}Options${optId}`}
                                                      //   type="checkbox"
                                                      //   label={option.name}
                                                      //   value={option.name}
                                                      //   checked={answer.sections[secId].questions[queId].answer.includes(option.name)}
                                                      //   onChange={(e) => changeField(e.target.value, 'answer', secId, queId)}
                                                      // />
                                                    ),
                                                  )}
                                                {
                                                  question.allowOther && (
                                                    <Checkbox
                                                      id={`answerSection${secId}Question${queId}ResponseType${
                                                        question.responseType
                                                      }Options${'other'}`}
                                                      style={styles}
                                                      value="other"
                                                      label={
                                                        question.otherOption
                                                      }
                                                      checked={answer.sections[
                                                        secId
                                                      ].questions[
                                                        queId
                                                      ].answer.includes(
                                                        'other',
                                                      )}
                                                      onChange={e =>
                                                        changeField(
                                                          e.target.value,
                                                          'answer',
                                                          question.responseTarget,
                                                          secId,
                                                          queId,
                                                        )
                                                      }
                                                    />
                                                  )
                                                  // <Form.Check
                                                  //   id={`answerSection${secId}Question${queId}ResponseType${question.responseType}Options${'other'}`}
                                                  //   type="checkbox"
                                                  //   label={question.otherOption}
                                                  //   value="other"
                                                  //   checked={answer.sections[secId].questions[queId].answer.includes('other')}
                                                  //   // onChange={(e) => changeField(e.target.value, 'answer', secId, queId)}
                                                  //   onChange={(e) => console.log(e.target.value)}
                                                  // />
                                                }
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>
                                        </Accordion>
                                      </Form.Group>
                                    )}

                                    {question.allowOther &&
                                      (answer.sections[secId].questions[queId]
                                        .answer === 'other' ||
                                        (question.responseType === 13 &&
                                          answer.sections[secId].questions[
                                            queId
                                          ].answer.includes('other'))) && (
                                        <Form.Group
                                          controlId={`answerSection${secId}Question${queId}Other`}
                                        >
                                          <Form.Label>
                                            {question.otherLabel}
                                          </Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder={
                                              question.otherPlaceholder
                                            }
                                            onChange={e =>
                                              changeField(
                                                e.target.value,
                                                'other',
                                                question.responseTarget,
                                                secId,
                                                queId,
                                              )
                                            }
                                            value={
                                              answer.sections[secId].questions[
                                                queId
                                              ].other
                                            }
                                          />
                                        </Form.Group>
                                      )}
                                    {question.allowDontKnow && (
                                      <Button
                                        id={`answerSection${secId}Question${queId}DontKnow`}
                                        variant="secondary"
                                        type="button"
                                        className="mt-2"
                                        onClick={() =>
                                          changeField(
                                            true,
                                            'dontKnowLabel',
                                            question.responseTarget,
                                            secId,
                                            queId,
                                          )
                                        }
                                      >
                                        {question.dontKnowLabel}
                                      </Button>
                                    )}
                                    {question.allowJustification && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}Justification`}
                                      >
                                        <Form.Label>
                                          {question.justificationLabel}
                                        </Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          placeholder={
                                            question.justificationPlaceholder
                                          }
                                          onChange={e =>
                                            changeField(
                                              e.target.value,
                                              'justification',
                                              question.responseTarget,
                                              secId,
                                              queId,
                                            )
                                          }
                                          value={
                                            answer.sections[secId].questions[
                                              queId
                                            ].justification
                                          }
                                        />
                                      </Form.Group>
                                    )}
                                    {question.allowAttachment && (
                                      <Form.Group
                                        controlId={`answerSection${secId}Question${queId}Attachment`}
                                      >
                                        <Form.File
                                          label={question.attachmentLabel}
                                        />
                                        {/* data-browse={question.attachmentPlaceholder} */}
                                      </Form.Group>
                                    )}
                                  </>
                                )}
                              </div>
                            ))
                        }
                      </>
                    )}
                  </div>
                ))
            }

            <div className="d-flex mt-2">
              {totalPages > 1 && (
                <>
                  <Button
                    id="formBack"
                    // variant="secondary"
                    type="button"
                    className={styles.button}
                    onClick={() => setPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Voltar
                  </Button>

                  <Button
                    id="formForward"
                    // variant="primary"
                    type="button"
                    className={styles.button}
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    Avançar
                  </Button>
                </>
              )}

              <Button
                id="formSave"
                // variant="success"
                type="submit"
                className={styles.button}
                disabled={currentPage !== totalPages}
              >
                Salvar Pesquisa
              </Button>
            </div>
          </Form>
        </Container>
      )}
    </div>
  );
};

export default withRouter(Answer);
