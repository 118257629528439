import { createSlice } from '@reduxjs/toolkit';

export const constructorSlice = createSlice({
  name: 'constructorState',
  initialState: {
    constructorList: [],
  },
  reducers: {
    setConstructorList: (state, action) => {
      state.constructorList = action.payload;
    },
    clearConstructorList: state => {
      state.constructorList = [];
    },
  },
});

export const {
  setConstructorList,
  clearConstructorList,
} = constructorSlice.actions;

export const selectConstructorList = state =>
  state.constructorState.constructorList;

export default constructorSlice.reducer;
