import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import Loading from './components/loading/Loading';
import Breadcrumb from './components/breadcrumbs/Breadcrumbs';
import ToastNotifications from './components/notifications/ToastNotifications';
import Header from './components/header/Header';
import GuardedRoute from './components/routes/GuardedRoute';
import Dashboard from './pages/dashboard/Dashboard';
import Products from './pages/products/Products';
import Clients from './pages/clients/Clients';
import ConstructorList from './pages/constructor/ConstructorList';
import User from './pages/users/User';
import NotFound from './pages/notfound/NotFound';
import Login from './pages/login/Login';
import AnswerParent from './pages/answer/AnswerParent';
import ForgotPassword from './pages/login/ForgotPassword';
import ResetPassword from './pages/login/ResetPassword';
import { setLoggedUser } from './pages/users/usersSlice';
import { login, logout, isAuthenticated } from './pages/login/loginSlice';

const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isAuthenticated);

  const getToken = useCallback(async () => {
    if (typeof window !== 'undefined') {
      const token = window.localStorage.getItem('token');
      // TODO:
      // Send token to an API endpoint to check if it is still valid
      if (token && !isLoggedIn) {
        dispatch(setLoggedUser(jwtDecode(token)));
        dispatch(login(token));
      } else if (!token) {
        dispatch(logout());
      }
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    getToken().then();
  }, [getToken]);

  return (
    <div className="App">
      <Loading />
      <ToastNotifications />
      <Router>
        <Header />
        <Breadcrumb />
        <Switch>
          <Route path="/entrar">
            <Login />
          </Route>
          <Route path="/esquecisenha">
            <ForgotPassword />
          </Route>
          <Route path="/resetsenha">
            <ResetPassword />
          </Route>
          <Route path="/responder">
            <AnswerParent />
          </Route>
          <GuardedRoute
            path="/clientes"
            component={Clients}
            auth={isLoggedIn}
          />
          <GuardedRoute
            path="/construtor"
            component={ConstructorList}
            auth={isLoggedIn}
          />
          <GuardedRoute path="/perfil" component={User} auth={isLoggedIn} />
          <GuardedRoute
            path="/produtos"
            component={Products}
            auth={isLoggedIn}
          />
          <GuardedRoute
            exact
            path="/"
            component={Dashboard}
            auth={isLoggedIn}
          />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
