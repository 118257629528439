import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';

import EmptyResults from '../../components/empty/EmptyResults';
import ProductCard from '../../components/cards/ProductCard';
import TextCard from '../../components/cards/TextCard';
import { get } from '../../app/axios';
import errorHandler from '../../app/error';
import { selectProducts, setProducts } from '../products/productsSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import { selectResearches, setResearches } from '../researches/researchesSlice';
import { selectClients, setClients } from '../clients/clientsSlice';
import {
  selectConstructorList,
  setConstructorList,
} from '../constructor/constructorSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const researches = useSelector(selectResearches);
  const clients = useSelector(selectClients);
  const constructorList = useSelector(selectConstructorList);

  const getData = useCallback(async () => {
    if (typeof window !== 'undefined') {
      const token = window.localStorage.getItem('token');
      if (!token) return;
    }

    dispatch(increaseLoadingState());
    dispatch(increaseLoadingState());
    dispatch(increaseLoadingState());

    if (products.length === 0) {
      dispatch(increaseLoadingState());
      try {
        get(`/products?limit=999`).then(productData => {
          if (productData.message) {
            productData.context = 'recuperar os produtos';
            errorHandler(productData);
          } else if (productData.products.length > 0) {
            dispatch(setProducts(productData.products));
          }
          dispatch(decreaseLoadingState());
        });
      } catch (error) {
        error.context = 'recuperar os produtos';
        errorHandler(error);
        dispatch(decreaseLoadingState());
      }
    }

    try {
      get(`/researches?limit=999`).then(researchData => {
        if (researchData.message) {
          researchData.context = 'recuperar as pesquisas';
          errorHandler(researchData);
        } else if (researchData.researches.length > 0) {
          dispatch(setResearches(researchData.researches));
        }
        dispatch(decreaseLoadingState());
      });
    } catch (error) {
      error.context = 'recuperar as pesquisas';
      errorHandler(error);
      dispatch(decreaseLoadingState());
    }

    try {
      get(`/clients?limit=999`).then(clientData => {
        if (clientData.message) {
          clientData.context = 'recuperar os clientes';
          errorHandler(clientData);
        } else if (clientData.clients.length > 0) {
          dispatch(setClients(clientData.clients));
        }
        dispatch(decreaseLoadingState());
      });
    } catch (error) {
      error.context = 'recuperar os clientes';
      errorHandler(error);
      dispatch(decreaseLoadingState());
    }

    try {
      get(`/quests?limit=999`).then(questData => {
        if (questData.message) {
          questData.context = 'buscar as pesquisas construídas';
          errorHandler(questData);
        } else if (questData.quests.length > 0) {
          dispatch(setConstructorList(questData.quests));
        }
        dispatch(decreaseLoadingState());
      });
    } catch (error) {
      error.context = 'buscar as pesquisas construídas';
      errorHandler(error);
      dispatch(decreaseLoadingState());
    }
  }, [dispatch, products.length]);

  useEffect(() => {
    getData().then();
  }, [getData]);

  return (
    <Container>
      <div className="m-5">
        <h1>Painel</h1>
      </div>
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4">
        <div>
          <TextCard
            quant={constructorList.length}
            title="Construtor"
            routeTo="/construtor"
            singleText="pesquisa construída."
            pluralText="pesquisas construídas."
            emptyText="Nenhuma pesquisa construída ainda."
            buttonText="Entrar"
            disabledButton={false}
          />
        </div>
        <div>
          <TextCard
            quant={clients.length}
            title="Clientes"
            routeTo="/clientes"
            singleText="cliente / lead já se cadastrou."
            pluralText="clientes / leads já se cadastraram."
            emptyText="Nenhum cliente respondeu ainda."
            buttonText="Entrar"
          />
        </div>
        <div>
          <TextCard
            quant={products.length}
            title="Produtos"
            routeTo="/produtos"
            singleText="produto cadastrado na plataforma."
            pluralText="produtos cadastrados na plataforma."
            emptyText="Nenhum produto cadastrado."
            buttonText="Entrar"
          />
        </div>
      </div>
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4">
        {products &&
          products.map((product, i) => (
            <div key={`product-${i}`}>
              <ProductCard researches={researches} product={product} />
            </div>
          ))}
        {products.length === 0 && (
          <EmptyResults
            title="Não há produtos configurados."
            message="Entre em contato com o administrador."
          />
        )}
      </div>
    </Container>
  );
};

export default Dashboard;
