import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Navbar,
  Nav,
  // NavDropdown,
} from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import { selectProducts } from '../../pages/products/productsSlice';
import { isAuthenticated, logout } from '../../pages/login/loginSlice';
import { clearLoggedUser } from '../../pages/users/usersSlice';

const Header = ({ location }) => {
  const [showMenuItems, setShowMenuItems] = useState(false);
  const [productId, setProductId] = useState('');
  const { pathname } = location;
  const isLoggedIn = useSelector(isAuthenticated);
  const products = useSelector(selectProducts);
  const product = products.find(prod => prod.type === productId);
  const dispatch = useDispatch();

  useEffect(() => {
    const productIdStr = pathname.replace('/produtos/', '').split('/')[0];
    if (!productIdStr) {
      const answerIdStr = pathname.replace('/responder/', '').split('/')[0];
      setProductId(answerIdStr);
    } else {
      setProductId(productIdStr);
    }

    if (pathname.includes('/responder/')) {
      setShowMenuItems(false);
    } else {
      setShowMenuItems(true);
    }
  }, [pathname]);

  const clickedOnLogout = () => {
    dispatch(logout());
    dispatch(clearLoggedUser());
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Navbar.Brand>
        {product && product.logo && (
          <div className="text-center logo-wrapper">
            <Link to={`/produtos/${product.type}/pesquisas`}>
              <img
                style={{ maxHeight: '60px' }}
                className="img-fluid"
                src={product.logo}
                alt={product.name}
                variant="top"
              />
            </Link>
          </div>
        )}
        {product && !product.logo && (
          <Nav>
            <Nav.Link as={Link} to={`/produtos/${product.type}/pesquisas`}>
              <img src={logo} className="App-logo" alt="logo" />
              <span>{product.name}</span>
            </Nav.Link>
          </Nav>
        )}
        {!product && (
          <Nav>
            <Nav.Link as={Link} to="/">
              <img src={logo} className="App-logo" alt="logo" />
              <span>Questionnaireflow</span>
            </Nav.Link>
          </Nav>
        )}
      </Navbar.Brand>
      {showMenuItems && <Navbar.Toggle aria-controls="responsive-navbar-nav" />}
      {showMenuItems && (
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="#features">Features</Nav.Link>
          <Nav.Link href="#pricing">Pricing</Nav.Link>
          <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
          </NavDropdown> */}
          </Nav>
          {isLoggedIn && showMenuItems && (
            <Nav>
              <Nav.Link as={Link} to="/">
                Painel
              </Nav.Link>
              <Nav.Link as={Link} to="/clientes">
                Clientes
              </Nav.Link>
              <Nav.Link as={Link} to="/produtos">
                Produtos
              </Nav.Link>
              <Nav.Link as={Link} to="/perfil">
                Perfil
              </Nav.Link>
              <Nav.Link as={Link} to="/construtor">
                Construtor
              </Nav.Link>
              <Nav.Link as={Link} to="/" onClick={clickedOnLogout}>
                Sair
              </Nav.Link>
            </Nav>
          )}
          {!isLoggedIn && showMenuItems && (
            <Nav>
              <Nav.Link as={Link} to="/entrar">
                Entrar
              </Nav.Link>
            </Nav>
          )}
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};

export default withRouter(Header);
