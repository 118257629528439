import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const EmptyResults = ({
  title,
  message = 'Não há dados a serem exibidos.',
  variant = 'light',
  redirectLink,
  redirectAction,
  customClass = 'text-center',
}) => {
  return (
    <Alert variant={variant} className={customClass}>
      {title && <Alert.Heading className="mb-3">{title}</Alert.Heading>}
      <p>{message}</p>
      {redirectLink && redirectAction && (
        <Alert.Link as={Link} to={redirectLink}>
          {redirectAction}
        </Alert.Link>
      )}
    </Alert>
  );
};

export default EmptyResults;
