import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import styles from '../../sass/pages/login.module.scss';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import { post } from '../../app/axios';
import errorHandler from '../../app/error';
import { displayNotification } from '../../components/notifications/notificationSlice';

const ForgotPassword = props => {
  const dispatch = useDispatch();
  const { history } = props;

  const [email, setEmail] = useState('');
  const [emailSent, setEmailBool] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    dispatch(increaseLoadingState());
    const credetinals = { email };
    try {
      const response = await post(`/auth/forgot_pass`, credetinals);
      if (response.message) {
        dispatch(
          displayNotification({
            title: 'Atenção!',
            text: 'Usuário não encontrado',
            variant: 'danger',
          }),
        );
      } else {
        dispatch(
          displayNotification({
            title: 'Sucesso!',
            text: 'Email de recuperação de senha enviado',
            variant: 'success',
          }),
        );
        setEmailBool(true);
        dispatch(decreaseLoadingState());
      }
    } catch (error) {
      error.context = 'recuperar usuário';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
  }

  return (
    <div className={styles.Login}>
      <h3 className={styles.title}>Esqueci minha senha</h3>
      {emailSent ? (
        <div>
          <h4> Seu email para recuperação de senha foi enviado com sucesso!</h4>
          <h5>
            Cheque sua caixa de spam, o código enviado tem expiração de 1 hora.
          </h5>
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Digite seu email"
              value={email}
              autoFocus
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" block>
            Enviar email
          </Button>
          <Button
            className="mt-2"
            variant="link"
            onClick={() => history.push('/entrar')}
          >
            Retornar para o login
          </Button>
        </Form>
      )}
    </div>
  );
};

export default withRouter(ForgotPassword);
