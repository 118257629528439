import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useParams,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from 'react-router-dom';
import { Container, Dropdown, DropdownButton, Button } from 'react-bootstrap';

import {
  setResearches,
  clearResearches,
  selectResearches,
} from '../researches/researchesSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import { selectProducts } from './productsSlice';

import errorHandler from '../../app/error';
import { get } from '../../app/axios';
import Researches from '../researches/Researches';
import Research from '../researches/Research';
import EmptyResults from '../../components/empty/EmptyResults';

const Product = props => {
  const match = useRouteMatch();

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit] = useState(20);

  const { location } = props;
  const { pathname } = location;
  const pathnames = pathname.split('/').filter(x => x);
  const previousRoute = pathname.replace(`${pathname}`, `/${pathnames[0]}`);

  const [filterStatus, setFilter] = useState('');
  const [title, setTitle] = useState('Filtrar pesquisas');
  const changeStatus = text => {
    switch (text) {
      case 'Todas as pesquisas':
      default:
        setTitle(text);
        setFilter('');
        break;
      case 'Pesquisas completas':
        setTitle(text);
        setFilter('&status=true');
        break;
      case 'Pesquisas incompletas':
        setTitle(text);
        setFilter('&status=false');
        break;
    }
    setPage(1);
  };

  const { productId } = useParams();
  const products = useSelector(selectProducts);
  const product = products.find(prod => prod.type === productId);

  let researches = useSelector(selectResearches);
  if (researches && researches.length > 0) {
    researches = researches.filter(research => research.type === productId);
  }

  const getResearches = useCallback(async () => {
    if (!product.type) return;

    dispatch(clearResearches());
    dispatch(increaseLoadingState());

    try {
      const response = await get(
        `/researches?type=${product.type}&limit=${limit}&page=${page}${filterStatus}`,
      );
      if (response.message) {
        response.context = 'recuperar as informações do cliente';
        errorHandler(response);
      } else if (response.researches && response.researches.length > 0) {
        dispatch(setResearches(response.researches));
        setTotalPage(response.totalPages);
      }
    } catch (error) {
      error.context = 'recuperar informações do produto';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
  }, [product.type, page, limit, filterStatus, dispatch]);

  useEffect(() => {
    getResearches().then();
  }, [getResearches]);

  const handleChangePage = _page => {
    setPage(_page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <Switch>
        <Route
          path="/produtos/:productId/pesquisas/:researchId"
          component={Research}
        />
        <Route path={match.path}>
          <div className="mr-5 ml-5 mt-5 d-flex justify-content-between">
            <h2>Pesquisas</h2>
            <div className="d-flex justify-content-between">
              <Button
                className="mr-2"
                style={{ height: '38px' }}
                variant="primary"
                onClick={() => getResearches()}
              >
                ⟳
              </Button>
              <DropdownButton id="dropdown-basic-button" title={title}>
                <Dropdown.Item
                  onSelect={(eventKey, event) =>
                    changeStatus(event.target.innerText)
                  }
                >
                  Todas as pesquisas
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={(eventKey, event) =>
                    changeStatus(event.target.innerText)
                  }
                >
                  Pesquisas completas
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={(eventKey, event) =>
                    changeStatus(event.target.innerText)
                  }
                >
                  Pesquisas incompletas
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          {researches.length > 0 && (
            <Researches
              researcheResults={researches}
              page={page}
              totalPages={totalPages}
              changePage={handleChangePage}
              previousRoute={`/produtos/${productId}`}
            />
          )}
          {researches.length > 0 && (
            <div className="ml-5 mb-5">
              <Link to={`${previousRoute}`}>
                <Button variant="secondary">Voltar</Button>
              </Link>
            </div>
          )}
          {researches.length === 0 && (
            <Container>
              <EmptyResults
                message={`Ninguém respondeu à pesquisa do ${product.name} ainda.`}
                redirectLink="/"
                redirectAction="Voltar"
              />
            </Container>
          )}
        </Route>
      </Switch>
    </div>
  );
};

export default Product;
