import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import PropTypes from 'prop-types';

const ToastNotification = ({ title, text, variant }) => {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <div>
        <Alert variant={variant} onClose={() => setShow(false)} dismissible>
          <Alert.Heading>
            <strong>{title}</strong>
          </Alert.Heading>
          <p>{text}</p>
        </Alert>
      </div>
    );
  }
  return <div />;
};

ToastNotification.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark',
  ]).isRequired,
};

export default ToastNotification;
