import store from './store';
import { displayNotification } from '../components/notifications/notificationSlice';
import { logout } from '../pages/login/loginSlice';

const errorHandler = error => {
  const title = 'Erro inesperado!';
  const variant = 'danger';
  const context = error.context || 'trabalhar as informações';
  let text = 'Contate o administrador.';

  if (error.response) {
    // Request made and server responded
    // console.error(
    //   'Error',
    //   error.response.data.message,
    //   error.response.status,
    //   error.response.headers,
    // );
    text = error.response.data.message || `Erro ao ${context}.`;

    if (error.response.status && error.response.status === 501) {
      store.dispatch(logout());
    }
  } else if (error.request) {
    // The request was made but no response was received
    // console.error('Error', error.request);
    text = `Não recebemos resposta do servidor para ${context}.`;
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.error('Error', error.message);
    text = `Não foi possível contactar o servidor para ${context}.`;
  }

  store.dispatch(displayNotification({ title, text, variant }));
};

export default errorHandler;
