import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { Container, Jumbotron, Button } from 'react-bootstrap';

import {
  setResearches,
  clearResearches,
  selectResearches,
} from '../researches/researchesSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import {
  selectDateLocale,
  selectDateOptions,
} from '../../components/date/dateSlice';
import { get, del } from '../../app/axios';
import { displayNotification } from '../../components/notifications/notificationSlice';

import errorHandler from '../../app/error';
import Researches from '../researches/Researches';
import Research from '../researches/Research';
import EmptyResults from '../../components/empty/EmptyResults';

const Client = props => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit] = useState(20);
  const [client, setCliente] = useState({});

  const { clientId } = useParams();

  const locale = useSelector(selectDateLocale);
  const options = useSelector(selectDateOptions);
  const researches = useSelector(selectResearches);

  const { location } = props;
  const { pathname } = location;
  const pathnames = pathname.split('/').filter(x => x);
  const previousRoute = pathname.replace(`${pathname}`, `/${pathnames[0]}`);

  const getResearches = useCallback(async () => {
    if (!clientId) return;

    dispatch(clearResearches());
    dispatch(increaseLoadingState());

    try {
      const response = await get(
        `/clients/${clientId}?limit=${limit}&page=${page}`,
      );
      if (response.message) {
        response.context = 'recuperar as informações do cliente';
        errorHandler(response);
      } else {
        setCliente(response.client);
        if (
          response.researches &&
          response.researches.list &&
          response.researches.list.length > 0
        ) {
          response.researches.list.map(research => {
            research.clientId = response.client;
            return research;
          });
          dispatch(setResearches(response.researches.list));
          setTotalPage(response.researches.totalPages);
        }
      }
    } catch (error) {
      error.context = 'recuperar as informações do cliente';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
  }, [clientId, page, limit, dispatch]);

  useEffect(() => {
    getResearches().then();
  }, [getResearches]);

  const sendEmail = async () => {
    if (!client._id) return;

    dispatch(increaseLoadingState());
    try {
      const response = await get(`/clients/${client._id}/client-token`);
      if (response && response.message && Object.keys(response).length === 1) {
        if (response.response && response.response.status !== 200) {
          response.context = 'enviar email para o cliente';
          errorHandler(response);
        } else {
          dispatch(
            displayNotification({
              title: 'Sucesso!',
              text: response.message,
              variant: 'success',
            }),
          );
        }
      }
    } catch (error) {
      error.context = 'enviar email para o cliente';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
  };

  const deleteClient = async () => {
    if (!client._id) return;

    dispatch(increaseLoadingState());
    // TODO:
    // Create a logic to also delete the client researches
    try {
      const response = await del(`/clients/${client._id}`);
      if (response && response.message && Object.keys(response).length === 1) {
        if (response.response && response.response.status !== 200) {
          response.context = 'apagar o cliente';
          errorHandler(response);
        } else {
          dispatch(
            displayNotification({
              title: 'Sucesso!',
              text: response.message,
              variant: 'success',
            }),
          );
          setCliente({});
        }
      } else {
        dispatch(
          displayNotification({
            title: 'Sucesso!',
            text: `${client.name} apagado`,
            variant: 'success',
          }),
        );
        setCliente({});
      }
    } catch (error) {
      error.context = 'apagar o cliente';
      errorHandler(error);
    }

    dispatch(decreaseLoadingState());
  };

  const handleChangePage = pageNum => {
    setPage(pageNum);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <Switch>
        <Route
          path="/clientes/:clientId/pesquisas/:researchId"
          component={Research}
        />
        <Route path={match.path}>
          {client && client._id && (
            <Jumbotron className="position-relative">
              <Button
                variant="danger"
                className="close-button mt-2 mr-2"
                onClick={() => deleteClient()}
              >
                Apagar
              </Button>
              <h3>{client.name}</h3>
              <p>
                <strong>ID: </strong>
                <span>{client._id}</span>
              </p>
              <p>
                <strong>Email: </strong>
                <a href={`mailto:${client.email}`}>{client.email}</a>
              </p>
              <p>
                <strong>Celular: </strong>
                <span>{client.phone}</span>
              </p>
              {client.role && (
                <p>
                  <strong>Cargo: </strong>
                  <span>{client.role}</span>
                </p>
              )}
              {client.motive && (
                <p>
                  <strong>Motivo: </strong>
                  <span>{client.motive}</span>
                </p>
              )}
              {client.sizeEnterprise && (
                <p>
                  <strong>Tamanho da empresa: </strong>
                  <span>{client.sizeEnterprise}</span>
                </p>
              )}
              {client.company && (
                <p>
                  <strong>Empresa: </strong>
                  <span>{client.company}</span>
                </p>
              )}
              <p>
                <strong>Criado em: </strong>
                <span>
                  {new Date(client.created_at).toLocaleDateString(
                    locale,
                    options,
                  )}
                </span>
              </p>
              <p>
                <strong>Última alteração: </strong>
                <span>
                  {new Date(client.updated_at).toLocaleDateString(
                    locale,
                    options,
                  )}
                </span>
              </p>
              <Button variant="primary" onClick={() => sendEmail()}>
                Enviar pesquisa por email
              </Button>
            </Jumbotron>
          )}
          {!client.email && (
            <EmptyResults
              message="Falha ao recuperar as informações do cliente."
              redirectLink="/clientes"
              redirectAction="Voltar"
            />
          )}
          <div className="mb-3 d-flex justify-content-between">
            <h2 className="ml-5 mb-3">Pesquisas</h2>
            <Button
              className="mr-5"
              style={{ height: '38px' }}
              variant="primary"
              onClick={() => getResearches()}
            >
              ⟳
            </Button>
          </div>
          {researches.length > 0 && (
            <Researches
              researcheResults={researches}
              page={page}
              totalPages={totalPages}
              changePage={handleChangePage}
              previousRoute={`/clientes/${clientId}`}
            />
          )}
          {client.email && researches.length > 0 && (
            <div className="ml-5 mb-5">
              <Link to={`${previousRoute}`}>
                <Button variant="secondary">Voltar</Button>
              </Link>
            </div>
          )}
          {researches.length === 0 && (
            <Container>
              <EmptyResults
                message="Este cliente ainda não respondeu à nenhuma pesquisa."
                redirectLink="/clientes"
                redirectAction="Voltar"
              />
            </Container>
          )}
        </Route>
      </Switch>
    </div>
  );
};

export default Client;
