import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';

import errorHandler from '../../app/error';
import ConstructorUnity from './ConstructorUnity';
import Researches from '../researches/Researches';
import Research from '../researches/Research';
import Paging from '../../components/paging/Paging';
import { get } from '../../app/axios';
import { selectConstructorList, setConstructorList } from './constructorSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import {
  selectDateLocale,
  selectDateOptions,
} from '../../components/date/dateSlice';

const ConstructorList = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [limit] = useState(20);

  const constructorList = useSelector(selectConstructorList);
  const locale = useSelector(selectDateLocale);
  const options = useSelector(selectDateOptions);

  const history = useHistory();

  const getConstructorList = useCallback(async () => {
    dispatch(increaseLoadingState());

    try {
      const response = await get(`/quests?limit=${limit}&page=${page}`);
      if (response && response.quests) {
        if (response.quests.length > 0) {
          dispatch(setConstructorList(response.quests));
          setTotalPage(response.totalPages);
        }
      } else if (response.message) {
        response.context = 'buscar as pesquisas construídas';
        errorHandler(response);
      }
    } catch (error) {
      error.context = 'buscar as pesquisas construídas';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
  }, [page, limit, dispatch]);

  useEffect(() => {
    getConstructorList().then();
  }, [getConstructorList]);

  const handleChangePage = pageNum => {
    setPage(pageNum);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <Switch>
        <Route path="/construtor/:constructorId" component={ConstructorUnity} />
        <Route
          path="/construtor/:constructorId/pesquisas"
          component={Researches}
        />
        <Route
          path="/construtor/:constructorId/pesquisas/:researchId"
          component={Research}
        />
        <Route path={match.path}>
          <div className="m-5">
            <h2 className="mb-3">Pesquisas Costruídas</h2>
            <div className="my-3">
              <Link to="/construtor/novapesquisa">
                <Button variant="primary">Nova Pesquisa</Button>
              </Link>
            </div>
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Nome</th>
                  <th>Versão</th>
                  <th>Data</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {constructorList &&
                  constructorList.map((constructor, i) => (
                    <tr
                      key={`constructor-${i}`}
                      onClick={() =>
                        history.push(`/construtor/${constructor._id}`)
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <td>{constructor.type.name}</td>
                      <td>{constructor.name}</td>
                      <td>{constructor.version}</td>
                      <td>
                        {new Date(constructor.created_at).toLocaleDateString(
                          locale,
                          options,
                        )}
                      </td>
                      <td>
                        <Link to={`/construtor/${constructor._id}`}>
                          <Button variant="primary" size="sm">
                            Selecionar
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {totalPages > 1 && (
              <Paging
                page={page}
                totalPages={totalPages}
                changePage={handleChangePage}
              />
            )}
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default ConstructorList;
