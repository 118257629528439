import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Badge,
  Jumbotron,
  Container,
  Form,
} from 'react-bootstrap';
import _cloneDeep from 'lodash-es/cloneDeep';

import {
  selectDateLocale,
  selectDateOptions,
} from '../../components/date/dateSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import { get, put } from '../../app/axios';
import errorHandler from '../../app/error';
import { displayNotification } from '../../components/notifications/notificationSlice';
import ResearchScore from './ResearchScore';

const Research = props => {
  const dispatch = useDispatch();

  const { researchId } = useParams();
  const { location } = props;
  const { pathname } = location;
  const previousRoute = pathname.replace(`/${researchId}`, '');

  const [globalComment, setGlobalComment] = useState('');
  const [commentMap, setCommentMap] = useState({});
  const [research, setResearch] = useState({});
  const locale = useSelector(selectDateLocale);
  const options = useSelector(selectDateOptions);

  const [lock, setLock] = useState('🔐');

  const getResearch = useCallback(async () => {
    if (!researchId) return;

    dispatch(increaseLoadingState());
    try {
      const response = await get(`/researches/${researchId}`);
      if (response.message) {
        response.context = 'recuperar as informações da pesquisa';
        errorHandler(response);
      } else {
        setResearch(response);
        toggleLock(response);
        mapComments(response);
      }
    } catch (error) {
      error.context = 'recuperar as informações da pesquisa';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
  }, [researchId, dispatch]);

  useEffect(() => {
    getResearch().then();
  }, [getResearch]);

  const mapComments = _research => {
    const commentMapClone = _cloneDeep(commentMap);
    let isThereIsComment = false;

    if (_research && _research.observations) {
      setGlobalComment(_research.observations);
    }

    for (
      let secIndex = 0;
      secIndex < _research.sections.length;
      secIndex += 1
    ) {
      const section = _research.sections[secIndex];
      for (
        let queIndex = 0;
        queIndex < section.questions.length;
        queIndex += 1
      ) {
        const question = section.questions[queIndex];
        if (question.observations) {
          const key = `sec${secIndex}-que${queIndex}`;
          commentMapClone[key] = {
            comment: question.observations,
            secIndex,
            queIndex,
          };
          isThereIsComment = true;
        }
      }
    }

    if (isThereIsComment) {
      setCommentMap(commentMapClone);
    }
  };

  const handleChange = (event, secIndex, queIndex) => {
    const commentMapClone = _cloneDeep(commentMap);

    const key = `sec${secIndex}-que${queIndex}`;
    commentMapClone[key] = {
      comment: event,
      secIndex,
      queIndex,
    };

    setCommentMap(commentMapClone);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    handlePutRequest('em andamento');
  };

  const handlePutRequest = async researchStatus => {
    // Deep clone
    const researchClone = JSON.parse(JSON.stringify(research));

    // Prepare to save global comment
    researchClone.observations = globalComment;

    // Prepare to save all questions comments
    const keys = Object.keys(commentMap);
    keys.forEach(key => {
      if (Object.hasOwnProperty.call(commentMap, key)) {
        const map = commentMap[key];
        researchClone.sections[map.secIndex].questions[
          map.queIndex
        ].observations = map.comment;
      }
    });

    researchClone.statusEvaluation = researchStatus;

    dispatch(increaseLoadingState());
    const response = await put(
      `/researches/${researchClone._id}`,
      researchClone,
    );
    if (response.message) {
      // TODO: notificações de erro
      dispatch(
        displayNotification({
          title: 'Atenção!',
          text: 'Erro inesperado.',
          variant: 'danger',
        }),
      );
    } else {
      dispatch(
        displayNotification({
          title: 'Sucesso!',
          text: 'Comentários salvos.',
          variant: 'success',
        }),
      );
      getResearch().then();
    }
    toggleLock(research);
    dispatch(decreaseLoadingState());
  };

  const handleFinalSubmit = async () => {
    handlePutRequest('finalizada');
  };

  const toggleLock = _research =>
    _research.statusEvaluation === 'finalizada' ? setLock('🔒') : setLock('🔐');

  const lockClicked = async event =>
    research.statusEvaluation === 'finalizada'
      ? handleSubmit(event)
      : handleFinalSubmit();

  const getComment = (_commentMap, key) =>
    _commentMap[key] ? _commentMap[key].comment : '';

  const getScoreClassName = score => {
    if (score < 0 || score === null || score === undefined) {
      return '';
    }
    if (score < 51) {
      return 'text-danger';
    }
    if (score < 76) {
      return 'text-warning';
    }
    return 'text-success';
  };

  const getScoreText = score => {
    if (score === null || score === undefined) {
      return 'N/A';
    }
    return `${score}%`;
  };

  const getBadgeClassName = _options => {
    if (_options === null || _options === undefined) {
      return 'secondary';
    }
    if (parseInt(_options, 10) === 0) {
      return 'danger';
    }
    if (parseInt(_options, 10) === 50) {
      return 'warning';
    }
    return 'success';
  };

  const getBadgeText = _options => {
    if (_options === null || _options === undefined) {
      return 'Não Respondida';
    }
    if (parseInt(_options, 10) === 0) {
      return 'Não Conforme';
    }
    if (parseInt(_options, 10) === 50) {
      return 'Parcialmente Conforme';
    }
    return 'Conforme';
  };

  return (
    <Container>
      {research && research._id && (
        <Form onSubmit={handleSubmit}>
          <Jumbotron>
            <h3>Pesquisa</h3>
            <p>
              <strong>Name: </strong>
              <span>{research.clientId.name}</span>
            </p>
            <p>
              <strong>Email: </strong>
              <a href={`mailto:${research.clientId.email}`}>
                {research.clientId.email}
              </a>
            </p>
            <p>
              <strong>Celular: </strong>
              <span>{research.clientId.phone}</span>
            </p>

            {research.clientId.role && (
              <p>
                <strong>Cargo: </strong>
                <span>{research.clientId.role}</span>
              </p>
            )}

            {research.clientId.motive && (
              <p>
                <strong>Motivo: </strong>
                <span>{research.clientId.motive}</span>
              </p>
            )}

            {research.clientId.sizeEnterprise && (
              <p>
                <strong>Tamanho da empresa: </strong>
                <span>{research.clientId.sizeEnterprise}</span>
              </p>
            )}

            {research.clientId.company && (
              <p>
                <strong>Empresa: </strong>
                <span>{research.clientId.company}</span>
              </p>
            )}

            {research.clientId.created_at && (
              <p>
                <strong>Data: </strong>
                <span>
                  {new Date(research.clientId.created_at).toLocaleDateString(
                    locale,
                    options,
                  )}
                </span>
              </p>
            )}

            {research.score !== 0 && (
              <p>
                <strong>Pontuação: </strong>
                <span className={getScoreClassName(research.score)}>
                  {research.score}
                </span>
              </p>
            )}

            {research.score === 0 ? (
              ''
            ) : (
              <ResearchScore score={research.score} />
            )}

            <Form.Group controlId="researchForm.GlobalComment">
              <Form.Label>Comentário Geral</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Digite seu comentário a respeito do resultado da pesquisa como um todo."
                onChange={e => setGlobalComment(e.target.value)}
                value={globalComment}
                disabled={research.statusEvaluation === 'finalizada'}
              />
            </Form.Group>
            <Link to={`${previousRoute}`}>
              <Button variant="secondary">Voltar</Button>
            </Link>
            <Button
              className="ml-2"
              variant="success"
              type="submit"
              disabled={research.statusEvaluation === 'finalizada'}
            >
              Salvar
            </Button>
          </Jumbotron>

          <div className="m-3">
            {research.sections &&
              research.sections.map((section, secIndex) => (
                <div className="ml-3" key={`section-${secIndex}`}>
                  <div className="d-flex flex-row">
                    {section.name && (
                      <h4 className="mr-2">Seção: {section.name}</h4>
                    )}

                    {section.name && (
                      <span className={getScoreClassName(section.sectionScore)}>
                        {getScoreText(section.sectionScore)}
                      </span>
                    )}
                  </div>

                  {section.description && (
                    <p>
                      <small>
                        <strong>Descrição: </strong>
                      </small>
                      <small
                        className={section.description ? '' : 'text-secondary'}
                      >
                        {section.description
                          ? section.description
                          : 'Esta seção está sem descrição'}
                      </small>
                    </p>
                  )}

                  {section.questions &&
                    section.questions.map((question, queIndex) => (
                      <div className="ml-3" key={`question-${queIndex}`}>
                        <p>
                          <strong>Pergunta: </strong>
                          <span>{question.question}</span>
                        </p>

                        {question.explanation && (
                          <p>
                            <small>
                              <strong>Explicação: </strong>
                            </small>
                            <small
                              className={
                                question.explanation ? '' : 'text-secondary'
                              }
                            >
                              {question.explanation
                                ? question.explanation
                                : 'Esta pergunta não tem explicação.'}
                            </small>
                          </p>
                        )}

                        {(question.options === null ||
                          question.options === 0 ||
                          question.options > 0) && (
                          <p>
                            <Badge
                              pill
                              variant={getBadgeClassName(question.options)}
                            >
                              {getBadgeText(question.options)}
                            </Badge>
                            {question.dontKnow && (
                              <Badge pill variant="secondary">
                                Não Sei
                              </Badge>
                            )}
                          </p>
                        )}

                        {question.answer && (
                          <p>
                            <strong>Resposta: </strong>
                            <span
                              className={
                                question.answer ? '' : 'text-secondary'
                              }
                            >
                              {question.answer
                                ? `${question.answer}`
                                : 'Não informada.'}
                            </span>
                          </p>
                        )}

                        <Form.Group
                          controlId={`researchForm.Sec-${secIndex}.Que-${queIndex}.Comment`}
                        >
                          <Form.Label>Comentário</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={1}
                            placeholder="Digite seu comentário a respeito dessa pergunta."
                            onChange={e =>
                              handleChange(e.target.value, secIndex, queIndex)
                            }
                            value={getComment(
                              commentMap,
                              `sec${secIndex}-que${queIndex}`,
                            )}
                            disabled={
                              research.statusEvaluation === 'finalizada'
                            }
                          />
                        </Form.Group>
                        <hr />
                      </div>
                    ))}
                </div>
              ))}
            <Link to={`${previousRoute}`}>
              <Button variant="secondary">Voltar</Button>
            </Link>

            <Button
              className="ml-2"
              variant="success"
              type="submit"
              disabled={research.statusEvaluation === 'finalizada'}
            >
              Salvar
            </Button>

            <ButtonGroup className="ml-2">
              <Button variant="primary" onClick={e => lockClicked(e)}>
                {lock}
              </Button>
              <Button
                variant="primary"
                disabled={research.statusEvaluation === 'finalizada'}
                onClick={() => handleFinalSubmit()}
              >
                Finalizar
              </Button>
            </ButtonGroup>
          </div>
        </Form>
      )}
    </Container>
  );
};

export default Research;
