import React, { useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { login, isAuthenticated } from './loginSlice';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import { setLoggedUser } from '../users/usersSlice';
import { post } from '../../app/axios';
import errorHandler from '../../app/error';
import { displayNotification } from '../../components/notifications/notificationSlice';
import styles from '../../sass/pages/login.module.scss';

const Login = props => {
  const dispatch = useDispatch();
  const { history } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isLoggedIn = useSelector(isAuthenticated);
  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    dispatch(increaseLoadingState());
    const credetinals = { email, password };
    try {
      const response = await post(`/auth`, credetinals);
      if (response.message) {
        response.context = 'realizar o login';
        errorHandler(response);
      } else {
        dispatch(login(response.access_token));
        dispatch(setLoggedUser(response.user));

        dispatch(decreaseLoadingState());

        dispatch(
          displayNotification({
            title: 'Sucesso!',
            text: 'Bem vindo novamente.',
            variant: 'success',
          }),
        );
        history.push('/');
      }
    } catch (error) {
      error.context = 'realizar o login';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
  }

  // function handleLoginError(error) {
  //   let errorMessage = '';
  //   switch (error) {
  //     case 'Invalid password':
  //       errorMessage = 'Senha inválida';
  //       break;
  //     case 'Unauthorized':
  //       errorMessage = 'Digite um usuário válido';
  //       break;
  //     case 'User not found':
  //       errorMessage = 'Usuário não encontrado';
  //       break;
  //     default:
  //       errorMessage = 'Não foi possível entrar no momento, tente novamente';
  //       break;
  //   }
  //   return errorMessage;
  // }

  return (
    <div className={styles.Login}>
      <h3 className={styles.title}>Login Teste</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Digite seu email"
            value={email}
            autoFocus
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Senha</Form.Label>
          <Form.Control
            type="password"
            placeholder="Senha"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" block>
          Entrar
        </Button>
        <Button
          className="mt-2"
          variant="link"
          onClick={() => history.push('/esquecisenha')}
        >
          Esqueci minha senha
        </Button>
      </Form>
    </div>
  );
};

export default withRouter(Login);
