import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import styles from '../../sass/pages/login.module.scss';
import {
  increaseLoadingState,
  decreaseLoadingState,
} from '../../components/loading/loadingSlice';
import { post } from '../../app/axios';
import errorHandler from '../../app/error';
import { displayNotification } from '../../components/notifications/notificationSlice';

const ResetPassword = props => {
  const dispatch = useDispatch();
  const { history, location } = props;
  const { search } = location;

  const searches = search.split('?');
  const token = searches[searches.length - 1];

  const [password, setPassword] = useState('');
  const [pwdSuccess, setPwdBool] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    dispatch(increaseLoadingState());
    const credetinals = { password, token };
    try {
      const response = await post(`/auth/reset_pass`, credetinals);
      if (response.message) {
        dispatch(
          displayNotification({
            title: 'Atenção!',
            text: '',
            variant: 'danger',
          }),
        );
      } else {
        // TODO: notificações de sucesso
        dispatch(
          displayNotification({
            title: 'Sucesso!',
            text: '',
            variant: 'success',
          }),
        );
        setPwdBool(true);
        dispatch(decreaseLoadingState());
      }
    } catch (error) {
      error.context = 'trocar senha';
      errorHandler(error);
    }
    dispatch(decreaseLoadingState());
  }

  return (
    <div className={styles.Login}>
      <h3 className={styles.title}>Resetar a senha</h3>
      {pwdSuccess ? (
        <div>
          <h4> Nova senha foi aplicada com êxito!</h4>
          <h5>Estreie-a utilizando no login</h5>
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Nova senha</Form.Label>
            <Form.Control
              type="password"
              placeholder="Digite sua nova senha"
              value={password}
              autoFocus
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Group>

          <Button variant="primary" type="submit" block>
            Resetar a senha
          </Button>
        </Form>
      )}
      <Button
        className="mt-2"
        variant="link"
        onClick={() => history.push('/entrar')}
      >
        Retornar para o login
      </Button>
    </div>
  );
};

export default withRouter(ResetPassword);
