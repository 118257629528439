import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    loggedUser: {},
    users: [],
  },
  reducers: {
    setLoggedUser: (state, action) => {
      state.loggedUser = action.payload;
    },
    clearLoggedUser: state => {
      state.loggedUser = {};
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    clearUsers: state => {
      state.users = [];
    },
  },
});

export const {
  setLoggedUser,
  clearLoggedUser,
  setUsers,
  clearUsers,
} = usersSlice.actions;

export const selectLoggedUser = state => state.users.loggedUser;
export const selectUsers = state => state.users.users;

export default usersSlice.reducer;
