import { configureStore } from '@reduxjs/toolkit';

import productsReducer from '../pages/products/productsSlice';
import clientsReducer from '../pages/clients/clientsSlice';
import constructorReducer from '../pages/constructor/constructorSlice';
import researchesReducer from '../pages/researches/researchesSlice';
import loadingReducer from '../components/loading/loadingSlice';
import loginReducer from '../pages/login/loginSlice';
import toastReducer from '../components/notifications/notificationSlice';
import usersReducer from '../pages/users/usersSlice';
import answerReducer from '../pages/answer/answerSlice';
import dateReducer from '../components/date/dateSlice';

export default configureStore({
  reducer: {
    products: productsReducer,
    clients: clientsReducer,
    constructorState: constructorReducer,
    researches: researchesReducer,
    loading: loadingReducer,
    login: loginReducer,
    toast: toastReducer,
    users: usersReducer,
    answer: answerReducer,
    date: dateReducer,
  },
});
